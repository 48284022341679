import { createAction, props } from '@ngrx/store';
import { GetSubordinatesWithPhaseStatusesForManagerResponse } from 'src/commonclasses';

export const RetrieveSuboardinatesWithPhaseStatuses = createAction(
  '[peopleAppraisal] retrieve subordinates with phase statuses from service',
  props<{
    getStrucureSubordinates?: boolean
  }>()
);

export const SetSuboardinatesWithPhaseStatuses = createAction(
  '[peopleAppraisal] set subordinates with phase statuses',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
);

export const SetIsLoadingSubordinates = createAction(
  '[peopleAppraisal] set is loading subordinates',
  props<{ payload: boolean }>()
);

export const SetStructureSuboardinatesWithPhaseStatuses = createAction(
  '[peopleAppraisal] set structure subordinates with phase statuses',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
);

export const UpdateSelfAssessmentUserStatusType = createAction(
  '[peopleAppraisal] update selfAssessment user status type',
  props<{
    payload: {
      userId: string,
      userStatus: any
    }
  }>()
);

export const UpdateSelfAssessmentData = createAction(
  '[peopleAppraisal] update selfAssessment status'
);

export const SetUpdatedSelfAssessmentData = createAction(
  '[peopleAppraisal] set selfAssessment updated data',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
)

export const SetHeaderTeamClickedButton = createAction(
  '[peopleAppraisal] set header team clicked button',
  props<{ payload: boolean }>()
);

export const SetActiveUserId = createAction(
  '[peopleAppraisal] set active user id',
  props<{ payload: string }>()
)

export const RemoveActiveUserId = createAction(
  '[peopleAppraisal] remove active user id',
)

export const RetrieveFeedbackSubordinates = createAction(
  '[peopleAppraisal] retrieve feedback 360 subordinates from service',
  props<{ roundId?: string }>()
);

export const SetFeedbackSubordinates = createAction(
  '[peopleAppraisal] set feedback 360 subordinates',
  props<{ payload: any[] }>()
);

export const SetIsLoadingFeedbackSubordinates = createAction(
  '[peopleAppraisal] set is loading feedback 360 subordinates',
  props<{ payload: boolean }>()
);

export const SetFeedbackSelectedRound = createAction(
  '[peopleAppraisal] set selected feedback 360 round',
  props<{ payload: any }>()
);