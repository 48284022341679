<div class="eval-wrapper">
  <div class="eval-user">
    <div class="eval-user__name">{{ feedback.author.forename }} {{ feedback.author.surname }}</div>
    <div class="eval-user__comment">{{ feedback.comment }}</div>
    <div class="eval-user__date">{{ feedback.feedbackDate | date:"dd.MM.yyyy" }}</div>
  </div>

  <div class="eval-rating">
    <competence-evaluation-system [direction]="'column'" [size]="'big'" [kind]="'default'" [items]="5" [readOnly]="true"
      [level]="level" [disabledFirstStar]="true" [isNotApplicable]="level == -1">
    </competence-evaluation-system>
  </div>
</div>