import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { DropdownItem } from '../../models/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '../../services/redirect.service';
import { PeerFeedbackServiceResponses } from 'atfcore-commonclasses';


@Component({
  selector: 'header-dossier',
  templateUrl: 'header-dossier.component.html',
  styleUrls: ['./header-dossier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDossierComponent implements OnInit {
  @Input() interview?: any;
  @Input() user: any = null;
  @Input() counter?: number;
  @Input() counterLabel?: string;
  @Input() selectedYear?: any;
  @Input() yearItems: any[] = [
    // {
    //   id: 2022,
    //   name: 'Anno 2022'
    // },
    // {
    //   id: 2021,
    //   name: 'Anno 2021'
    // }
  ];
  @Input() attrAriaLabel: string = '';
  @Input() menuButtons: any[] = [];
  @Input() hasTabs: boolean = true;
  @Input() isAdminHeader: boolean = false;
  @Input() isFeedback360: boolean = false;
  @Input() isDashboard: boolean = false;
  @Input() isChief: boolean = false;
  @Input() isPeopleAppraisal: boolean = false;
  @Input() isSelfAssessment: boolean = false;
  @Input() adminActionLabel: string = '';
  @Input() adminActionIcon: string = '';
  @Input() adminPageTitle: string = '';
  @Input() adminPageSubtitle: string = '';
  @Input() adminBack: boolean = false;
  @Input() adminActionDisabled: boolean = false;
  @Input() adminActionLabelType: string = 'primary';
  @Input() showLoader: boolean = false;
  // Lista tab custom
  @Input() customTabList: boolean = false;
  @Input() hideAction: boolean = false;
  @Input() currentMenuButton: any;
  @Input() counterTabs: PeerFeedbackServiceResponses.CountNotesAndFeedbacksForUser = { feedbacks: 0, privateNotes: 0 };
  /** Se true, la select è disabilitata */
  @Input() disabled: boolean = false;
  @Input() currentPercentage: any;

  @Input() selectedRound?: any;
  @Input() roundList: any[] = []
  @Input() isAdminMenu: boolean = false;

  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onNewInterview: EventEmitter<any> = new EventEmitter();
  @Output() onAnnullInterview: EventEmitter<any> = new EventEmitter();
  @Output() onMenuButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() onAdminAction: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedYear: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedRound: EventEmitter<any> = new EventEmitter();

  isOpenedAdminMenu: boolean = false;
  menuOptions: Array<{ id: string, title: string, icon?: string }> = [];
  availibleNotes: boolean = false;
  selectedMenuButton: any;
  noDataObject: DropdownItem = {
    id: '',
    name: '',
    icon: ''
  };
  mobileMenuOpened: boolean = false;
  mobileMenuItems: any[] = [];
  count = '0';
  isPeopleAppraisalModule: boolean = false;
  constructor(
    public translate: TranslateService,
    public redirectService: RedirectService
  ) {
  }

  ngOnInit() {
    this.isPeopleAppraisalModule = window.location.href.indexOf('/peopleAppraisal/') >= 0;
    this.translate.get(
      [
        'generic.NO_DATA_FOUND',
        'admin.filters.EQUAL',
        'admin.SCHEDULE',
        'headerDossier.CARD',
        'headerDossier.DEVELOPMENT_CARD',
        'headerDossier.PRIVATE_NOTE',
        'headerDossier.FEEDBACK_TITLE',
        'headerDossier.REPORT_PROCESS',
        'headerDossier.REPORT_FEEDBACK'
      ]).subscribe(translations => {
        this.noDataObject = {
          id: 'none',
          name: translations['generic.NO_DATA_FOUND'],
          icon: '/assets/img/icons/coffee.svg'
        }

        this.menuOptions = [
          {
            id: 'process',
            title: translations['headerDossier.REPORT_PROCESS'],
          },
          {
            id: 'feedback',
            title: translations['headerDossier.REPORT_FEEDBACK'],
          }
        ]

        if (this.menuButtons && this.menuButtons.length) {
          if (window.location.href.includes('/myNotes/')) {
            this.selectedMenuButton = this.menuButtons[2];
          } else if (window.location.href.includes('/feedback/')) {
            this.selectedMenuButton = this.menuButtons[1];
          }
          else {
            if (this.currentMenuButton != undefined) {
              this.selectedMenuButton = (this.menuButtons.find(el => el.id == this.currentMenuButton.id) != undefined) ? this.currentMenuButton : this.menuButtons[0];
            } else {
              this.selectedMenuButton = this.menuButtons[0]
            }
          }
        }

      });
  }

  openAdminMenu(close?: boolean) {
    if (close) {
      this.isOpenedAdminMenu = false;
    } else {
      this.isOpenedAdminMenu = !this.isOpenedAdminMenu;
    }

  }

  ngDoCheck() {
    this.availibleNotes = this.isPeopleAppraisal && !this.isSelfAssessment
  }

  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  emitOnSelectedYearItem(item: any) {
    this.selectedYear = item;
    this.onSelectedYear.emit(item);
  }

  emitOnSelectedRound(item: any) {
    this.selectedRound = item;
    this.onSelectedRound.emit(item);
  }

  emitOnOptionClicked(item: any) {
    this.mobileMenuOpened = !this.mobileMenuOpened;
    item.badgeNumber = 0;
    this.selectedMenuButton = item;
    this.mobileMenuItems = this.menuButtons.filter((x: any) => {
      return x.id != item.id;
    })
    this.onMenuButtonClicked.emit(item.id);
  }

  emitOnOrganizeinterview() {
    this.onNewInterview.emit();
  }

  emitOnAnnullInterview(feedbackDialogueId: string) {
    this.onAnnullInterview.emit(feedbackDialogueId);
  }

  // Quando premi nel mobile un pulsante del menu bottom
  menuButtonClicked(selectedItem: any) {
    if (window.screen.width <= 600 || document.documentElement.clientWidth <= 600) {
      this.mobileMenuOpened = !this.mobileMenuOpened;
    }
    selectedItem.badgeNumber = 0;
    this.selectedMenuButton = selectedItem;
    this.mobileMenuItems = this.menuButtons.filter((x: any) => {
      return x.id != selectedItem.id;
    })
    this.onMenuButtonClicked.emit(selectedItem.id);
  }

  emitOnAdminAction(id?: string) {
    this.onAdminAction.emit(id);
  }
}