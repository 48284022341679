import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerfCarePhasesStatusTypes } from 'src/commonclasses';
import { SelfAssessmentUtils } from 'src/app/utils/selfAssessment.utils';

@Component({
  selector: 'item-list-team',
  templateUrl: 'item-list-team.component.html',
  styleUrls: ['./item-list-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemListTeamComponent implements OnInit {
  _user: any = null;
  @Input() set user(value: any) {
    this._user = value;
    this.formatUserStatus();
  };
  @Input() circlePercentage: boolean = false;
  @Input() title: string = '';
  @Input() activeUserId: string | null = null;
  @Input() currentPhaseId: string = '';
  // refactor dati utente
  userName: string = '';
  initials: string = '';
  avatar: string = '';
  persentage: string = '';
  // utils per css
  containerClass: string = 'item-list-container';
  userPhaseStatus: string = 'disabled';
  itemStatus: string = 'active';
  size: string = 'medium';
  isActive: boolean = false;

  @Output() userClicked: EventEmitter<any> = new EventEmitter<any>();
  onExpand: boolean = false;

  constructor() {
  }

  ngOnInit() {
    if (this._user && this._user.userId) {
      this.initials = this._user.forename[0] + this._user.surname[0];
      this.userName = this._user.surname + ' ' + this._user.forename;
      this.avatar = this._user.userOptions && this._user.userOptions.avatarImage;
      if (this.activeUserId && this._user.userId === this.activeUserId) {
        this.isActive = true;
      }
      if (this._user.status) {
        this.formatUserStatus();
      } else {
        this.userPhaseStatus = 'toStart';
        this.itemStatus = 'toStart';
      }
      this.persentage = this._user.completedPasPercentage;
    }
  }

  // Formatta lo stato utente
  formatUserStatus() {
    this.userPhaseStatus = SelfAssessmentUtils.getUserStatus(this._user);
    if (this.userPhaseStatus === 'disabled') {
      this.itemStatus = 'disabled';
    }
  }

  expand() {
    this.onExpand = true;
    this.containerClass = 'hover expand';
  }

  collapse() {
    this.onExpand = false;
    this.containerClass = 'item-list-container collapse';
  }

  emitOnUserClicked() {
    this.userClicked.emit((this._user && this._user.userId) || null);
  }
}