<div class="item-list-container" [ngClass]="
  {
    'disabled': !(_user && _user.status &&_user.status.statusType == 'COMPLETED') && _user?.structureUser,
    'active': activeUserId === (_user && _user.userId), 'expand': onExpand, 'collapse': !onExpand
  }"
  (mouseenter)="expand()" (mouseleave)="collapse()" (click)="emitOnUserClicked()">
  <avatar-img [secondStructure]="_user?.structureUser" [user]="_user" size="medium"></avatar-img>
  <div class="more-info-container">
    <p>{{ userName | truncate: [22, '...'] }}</p>
    <item-list-team-status [type]="userPhaseStatus" styleItem="fill"
      [message]="('itemListTeamStatus.' + userPhaseStatus)"></item-list-team-status>
    <p *ngIf="title">{{title}}</p>
  </div>
  <div class="arrow"></div>
</div>