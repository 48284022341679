import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { DropdownPosition } from '@ng-select/ng-select';

@Component({
  selector: 'pce-select',
  templateUrl: './pce-select.component.html',
  styleUrls: ['./pce-select.component.scss'],
})
export class PceSelectComponent implements ControlValueAccessor, AfterViewInit {
  constructor(){ this.ngControl ? this.ngControl.valueAccessor = this : undefined }

  private ngControl = inject(NgControl, { optional: true });
  private cdr = inject(ChangeDetectorRef);

  @Input() items!: any[];
  @Input() title?: string;
  @Input() bindLabel!: string;
  @Input() bindValue?: string;
  @Input() customLabel?: any;
  @Input() multiple: boolean = false;
  @Input() searchable:boolean = false;
  @Input() closeOnSelect: boolean = true;
  @Input() required?:boolean;
  @Input() disabled?:boolean;
  @Input() compareWith: any;
  @Input() isLoading: boolean = false;
  @Input() clearable: boolean = true;
  @Input() loadingText: string = "Caricamento...";
  @Input() notFoundText: string = "Nessun risultato";
  @Input() placeholder: string = "Seleziona...";
  @Input() dropdownPosition: DropdownPosition = "auto";

  @Output() onChange = new EventEmitter<any[]>();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onClear = new EventEmitter<void>();
  @Output() onFocus = new EventEmitter<void>();
  @Output() onAdd = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Output() onScrollToEnd = new EventEmitter<void>();
  @Output() scroll = new EventEmitter<{ start: number, end: number }>();

  protected _required?: boolean;
  private _value: any;
  protected _disabled: boolean = false;
  protected _displayError?: ValidationErrors | null;
  private onTouched = () => {};
  private onChanged = (_: any) => {};

  ngAfterViewInit(): void {
    // Setta l'obbligatorietà del componente per visualizzare *
    this._required = this.ngControl?.control?.hasValidator(Validators.required);
    this.cdr.detectChanges();
  }
  checkForError() {
    if (!this.ngControl) return;
    const { errors, dirty, touched } = this.ngControl
    if (this.ngControl.errors && (dirty || touched)) {
      this._displayError = errors
      return true
    }
    return null
  }

  get value() {
    return this._value;
  }
  set value(value: any) {
    this._value = value;
    this.onChanged(value);
  }
  onBlur() {
    this.onTouched();
  }
  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
}