import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alloy-radio',
  templateUrl: 'alloy-radio.component.html',
  styleUrls: ['./alloy-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropRadioComponent implements OnInit {
  @Input() radioName: string = 'Prova';
  // @Input() layout: string = "row"
  @Input() items: any[] = [];
  @Input() plainItems?: string[];
  @Input() withLabel: boolean = true;
  @Input() bindValue: any;
  @Input() bindValueIndex: number = -1;
  @Input() switch: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Output() onItemChange: EventEmitter<any> = new EventEmitter();

  formattedItems: { id: number, value: string }[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    //this.bindValue = this.items && this.items[0];
    if (this.plainItems && this.plainItems.length) {
      for (let i = 0; i < this.plainItems.length; i++) {
        this.formattedItems.push({
          id: i,
          value: this.plainItems[i]
        })
      }
    }
  }

  setValue(item: any, index?: number) {
    this.bindValue = item;
    if (index) {
      this.bindValueIndex = index;
    }
    this.cdr.detectChanges();
    this.emitOnItemChange(index);
  }


  emitOnItemChange(index?: number) {
    if (index != undefined && index >= 0) {
      this.onItemChange.emit({
        id: index,
        value: this.bindValue
      });
    } else {
      this.onItemChange.emit(this.bindValue);
    }
  }
}