<div *ngIf="currentPerformer" class="performer-evaluator-container">
  <p *ngIf="!hideSectionTitle" class="section-title" translate="recap.TITLE"></p>
  <!-- Immagine -->
  <div class="evaluator-img-container">
    <svg-icon [applyClass]="true" [src]="currentPerformer.imgSrc">
    </svg-icon>

    <!-- Numero della valutazione -->
    <div class="evaluator-number" *ngIf="_performerValue && !hideValue">
      <p>{{ _performerValue }}</p>
    </div>

    <!-- Numero ancora da impostare -->
    <div class="evaluator-number-to-set" *ngIf="hasToSetVal && !hideValue">
      <p>?</p>
    </div>
  </div>

  <!-- Titolo -->
  <div class="title" *ngIf="!hideTitle && currentPerformer.title">
    <p>{{ currentPerformer.title }}</p>
    <!-- Pulsante per le informazioni -->
    <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true" (click)="emitOpenPerformerModal()"
      *ngIf="enablePerformerInfo">
    </svg-icon>
  </div>

  <!-- Descrizione -->
  <div class="description" [ngClass]="{'min-height': currentPerformer !== 'TODO' && currentPerformer !=='OVERALL'}">
    <p>{{ currentPerformer.description }}</p>
  </div>

  <!-- Scheda inviata all'HR -->
  <div class="sent-to-hr" *ngIf="sentToHr">
    <div class="sent-to-hr-label">
      <p translate="performerEvaluators.SENT_TO_HR"></p>
    </div>
  </div>

  <!-- Pulsante per aprire le osservazioni HR e self-assessment -->
  <div class="show-hr-remarks" *ngIf="enableHrRemarks && !isHrRemarksVisible">
    <div class="show-hr-remarks-label">
      <p (click)="toggleHrRemarks()" translate="performerEvaluators.SHOW_HR_REMARKS"></p>
    </div>
  </div>

  <!-- Osservazioni HR e self-assessment -->
  <div class="hr-remarks" *ngIf="enableHrRemarks && isHrRemarksVisible">
    <evaluation-rating [evaluationTitle]="'performerEvaluators.HR_REMARKS' | translate" [evaluationText]="hrRemarks"
      [isHr]="true">
    </evaluation-rating>
  </div>
</div>