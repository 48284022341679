/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { TranslateService } from '@ngx-translate/core';
import { SenecaResponse } from 'atfcore-commonclasses';

@Injectable()
export class LangsService {
  private applicationContext = this.urlService.getApplicationUrl().baseUrl;
  constructor(private http: HttpClient,
    private urlService: UrlService,
    private translate: TranslateService) { }

  // Recupera le lingue disponibili a sistema
  getAvailableLangs(): any {
    return this.http.get<any>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-all-langs');
  }

  // Recupera la lingua di default del browser
  getBrowserLang(): string {
    return this.translate.getBrowserLang() ?? "it";
  }

  // Lingua attualmente in uso recuperata dal Token
  getUserLang = (user: any) => {
    return user && user.userOptions && user.userOptions.langCode ? user.userOptions.langCode.substring(0, 2) : "it";
  }

  // Metodo che cambia la lingua e le relative traduzioni all'appplicazione
  useLanguage(language: string) {
    this.translate.use(language);
  }

  // Trova l'indice di una lingua all'interno di un Item
  findItemLangIndex = (langCode: string, item: any) => {
    let index: number = -1;
    if (item && item.itemLangs) {
      item.itemLangs.forEach((one: any, idx: number) => {
        if (one.langCode === langCode) {
          index = idx;
        }
      });
    }
    return index;
  }

  updateUserLanguage(langCode: string) {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + "rest-api/alloyperformance-mediator/update-user-language", {
      langCode: langCode
    })
  }
}