<p *ngIf="title" class="select__title">{{ title }}<span *ngIf="_required || required">*</span></p>
<ng-select
[items]="items"
[bindLabel]="bindLabel"
[virtualScroll]="true"
[closeOnSelect]="closeOnSelect"
[multiple]="multiple"
[disabled]="disabled ?? _disabled"
[searchable]="searchable"
[bindValue]="bindValue ?? ''"
[compareWith]="compareWith"
[clearable]="clearable"
[placeholder]="placeholder"
[dropdownPosition]="dropdownPosition"
[loading]="isLoading"
[loadingText]="loadingText"
[notFoundText]="notFoundText"
[(ngModel)]="value"
(change)="onChange.emit($event)"
(add)="onAdd.emit($event)"
(remove)="onRemove.emit($event)"
(search)="onSearch.emit($event.term)"
(scrollToEnd)="onScrollToEnd.emit()"
(scroll)="scroll.emit($event)"
(clear)="onClear.emit()"
(focus)="onFocus.emit()"
(blur)="onBlur()">
  <ng-container *ngIf="customLabel">
    <ng-template ng-label-tmp let-item="item" let-clear="clear" let-label="label">
      <ng-container
        [ngTemplateOutlet]="customLabel"
        [ngTemplateOutletContext]="{ $implicit: item, clear: clear, label: label }">
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-template ng-notfound-tmp>
    <div class="ng-option" style="display: flex; align-items: center">
      <img style="margin-right: 10px" src="./assets/img/icons/coffee.svg">
      <p>Nessun dato trovato</p>
    </div>
  </ng-template>
</ng-select>

<ng-container *ngIf="checkForError()">
  <p style="color: red;" *ngIf="_displayError?.['required']">Errore: parametro obbligatorio</p>
  <p style="color: red;" *ngIf="_displayError?.['minlength']">Errore: inserisci almeno {{ _displayError?.['minlength']['requiredLength'] }} elementi</p>
</ng-container>