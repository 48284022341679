/*
* Pipe per rendere l'html fidato affinché l'Angular DOM sanitizer non elimini parti
*/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials'
})
export class UserInitialPipe implements PipeTransform {
  constructor() {
  }
  public transform(value: any): string {
    if (value && value.forename && value.surname) {
      return value.surname[0] + value.forename[0];
    }
    return 'err';
  }
}