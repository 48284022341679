import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetSubordinatesWithPhaseStatusesForManagerResponse, PerfAlloyPrivateNoteFeedback, User } from 'atfcore-commonclasses';
import moment from 'moment';

@Component({
  selector: 'card-notes',
  templateUrl: 'card-notes.component.html',
  styleUrls: ['./card-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardNotesComponent implements OnInit {
  @Input() note!: PerfAlloyPrivateNoteFeedback;
  @Input() loggedUser?: GetSubordinatesWithPhaseStatusesForManagerResponse;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  creationDateText: string = '';
  textList: Array<{ title: string, desc: string }> = []
  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
    this.creationDateText = moment(this.note.creationDate).fromNow();
    
    // Controllo se esiste la motivazione (parametro opzionale)
    if (this.note.motivation) {
      this.textList = [
        {
          title: this.translate.instant('feedbackSection.privateNotes.MOTIVATION'),
          desc: this.note.motivation
        }]
    }
    // Aggiungo poi il commento che è obbligatorio
    this.textList.push({
      title: this.translate.instant('feedbackSection.privateNotes.NOTE'),
      desc: this.note.comment
    })
  }

  emitOnEditNote(id: string) {
    this.onEdit.emit(id);
  }

  emitOnDeleteNote(id: string) {
    this.onDelete.emit(id)
  }
}