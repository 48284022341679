<page-container *ngIf="langs && langs.length">

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content" *ngIf="login">
    <div class="page-login-content">
      <ng-container *ngIf="!showLoader">
        <div class="login-wrapper">
          <svg-icon src="assets/img/icons/sol-big-logo.svg" class="login-icon" [applyClass]="true"></svg-icon>
          <div class="container-title">
            <h1><b translate="login.ACCESS"></b></h1>
            <p class="login-title-text" translate="login.TITLE_PAGE"></p>
          </div>
          <input-container [tooltipModalMessage]="usernameModalMessage" [removePadding]="true" [isValued]="false"
            [isObbligatory]="true" title="{{'login.MAIL' | translate}}">
            <alloy-input [customFormControl]="emailControl" [hasFormControl]="true" [isInvalid]="emailError()"
              [iconRight]="iconRight"
              [errorText]="!emailValidate() && loginForm.email && loginForm.email.trim().length? ('login.EMAIL_ERROR_INSERTED' | translate) : ''"
              autocomplete="on" (onIconClicked)="onLogin()" typeInput="email" [bindValue]="loginForm.email"
              (onModelChanged)="emailChanged($event)" placeholder="{{'login.MAIL_PLACEHOLDER' | translate}}">
            </alloy-input>
          </input-container>
          <input-container [tooltipModalMessage]="passwordModalMessage" [removePadding]="true" [isValued]="false"
            [isObbligatory]="true" title="{{'login.PASSWORD' | translate}}">
            <alloy-input [hasFormControl]="true" [iconRight]="iconRightEye" autocomplete="on" [typeInput]="typeInput"
              [bindValue]="loginForm.password" (onModelChanged)="passwordChanged($event)"
              (onIconClicked)="changeTypeInput(typeInput)" placeholder="{{'login.PASSWORD_PLACEHOLDER' | translate}}"
              (keyup.enter)="!loginButtonIsActive() || !emailValidate()? '': onLogin()">
            </alloy-input>
          </input-container>
          <div class="button-login-container">
            <alloy-button [disabled]="!loginButtonIsActive() || !emailValidate()" type="primary fill" size="large"
              label="{{'login.SIGN_IN_BUTTON' | translate}}" (onButtonClicked)="onLogin()">
            </alloy-button>
          </div>
          <div class="password-recovery">
            <a role="button" (click)="openPasswordRecoveryModal()"><b>Recupera password</b></a>
          </div>
          <div class="divider-or">
            <div class="divider"></div>
            <p>Oppure</p>
            <div class="divider"></div>
          </div>
          <div class="sign-up">
            <div class="container-title-sign-up">
              <h1><b translate="login.SIGN_UP_BUTTON"></b></h1>
              <p class="login-title-text" translate="login.TITLE_SIGN_IN_OPTION"></p>
            </div>
            <alloy-button size="large" type="secondary" label="{{'login.SIGN_UP_BUTTON' | translate}}"
              (onButtonClicked)="goToSignUp(true)">
            </alloy-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showLoader">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>

    <!--Modale recupera password-->
    <modal id="modalPasswordRecovery" class="modal-Recovery" [isMessageModal]="true"
      (onClose)="closePasswordRecoveryModal(true)" confirmLabel="{{'login.modals.CONFIRM' | translate}}"
      cancelLabel="{{'login.modals.CANCEL' | translate}}" (onCancel)="closePasswordRecoveryModal(true)"
      (onConfirm)="closePasswordRecoveryModal(false)">
      <modal-text-content title="{{'login.modals.PASSWORD_RECOVERY' | translate}}"
        subtitle="{{'login.modals.PASSWORD_RECOVERY_SUBTITLE' | translate}}"
        text="{{'login.modals.PASSWORD_RECOVERY_DESCR' | translate}}">
      </modal-text-content>
    </modal>
    <!--Modale email non inserita-->
    <modal id="modalEmailWarning" class="modal-Recovery" [isMessageModal]="true" (onClose)="closeEmailWarningModal()"
      (onConfirm)="closeEmailWarningModal()" confirmLabel="{{'login.modals.CLOSE' | translate}}">
      <modal-text-content title="{{'login.modals.EMAIL_WARNING' | translate}}"
        subtitle="{{'login.modals.EMAIL_WARNING_SUBTITLE' | translate}}"
        text="{{'login.modals.EMAIL_WARNING_DESCR' | translate}}">
      </modal-text-content>
    </modal>
    <!--Modale mail inviata-->
    <modal id="modalEmailSent" class="modal-Recovery" [isMessageModal]="true" (onClose)="closeEmailSentModal()"
      (onConfirm)="closeEmailSentModal()" confirmLabel="{{'login.modals.CLOSE' | translate}}">
      <modal-text-content title="{{'login.modals.EMAIL_SENT' | translate}}"
        subtitle="{{'login.modals.EMAIL_SENT_SUBTITLE' | translate}}"
        text="{{'login.modals.EMAIL_SENT_DESCR' | translate}}">
      </modal-text-content>
    </modal>
    <!--Modale mail confermata-->
    <modal id="modalSuccessfulSignUp" class="modal-successful-Email" [isMessageModal]="true"
      (onClose)="closeSuccessfulSignUpModal()" (onConfirm)="closeSuccessfulSignUpModal()"
      confirmLabel="{{'login.modals.CLOSE' | translate}}">
      <modal-text-content title="{{'login.modals.SUCCESSFUL_SIGN_UP' | translate}}"
        text="{{'login.modals.SUCCESSFUL_SIGN_UP_DESCR' | translate}}">
      </modal-text-content>
    </modal>
  </div>
</page-container>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>