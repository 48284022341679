import { GetSubordinatesWithPhaseStatusesForManagerResponse, PerfAlloyFeedbackStatus, User } from "../../commonclasses";
import { UserWithFeedback360Status } from "../peopleAppraisal/services/subordinates.service";
import { UserCard } from "./classes.utils";

export class FeedbackUser extends User {
  status?: PerfAlloyFeedbackStatus;
}

// Utility per la formattazione dei dati utente per il componente user-card
export class UserDataUtils {

  // Funzione da chiamare nel componente sulla risposta del servizio
  static getUserCardData(users: GetSubordinatesWithPhaseStatusesForManagerResponse[] | Array<User>) {
    let tmp: UserCard[] = [];
    if (users && users.length) {
      for (let i = 0; i < users.length; i++) {
        tmp.push(this.formatUserCardData(users[i]))
      }
    }
    return tmp;
  }

  // funzione di supporto che ritorna la card per 1 utente
  static formatUserCardData(user: GetSubordinatesWithPhaseStatusesForManagerResponse | User) {
    return {
      id: user.userId,
      initials: user.surname[0] + user.forename[0],
      name: user.forename,
      surname: user.surname,
      avatar: user.userOptions ? user.userOptions.avatarImage : '',
      isChecked: false,
      // role: user.ruolo
    }
  }

  static formatFeedbackList(userDataList: UserWithFeedback360Status[]) {
    let tmp: any[] = [];
    if (userDataList && userDataList.length) {
      userDataList.forEach((userData: any) => {
        tmp.push({
          ...userData.user,
          status: { statusType: userData.status }
        })
      })
    }
    return tmp;
  }
}

export interface GetSubordinatesWithPhaseStatusesForManagerResponseFE extends GetSubordinatesWithPhaseStatusesForManagerResponse {
  ruolo: string;
}