import { TranslateService } from "@ngx-translate/core";

export interface Performer {
  key: string;
  title: string;
  description: string;
  minValue: number;
  maxValue: number;
  imgSrc: string;
  performerValue?: number;
  abbreviation?: string;
};

export class PerformerClass {
  [code: string]: Performer;
}

export class PerformerEvaluatorUtils {
  // Torna la lista dei performer
  static getPerformers(translate: TranslateService, isToDo?: boolean, overall?: boolean, isSelfView?: boolean): any {
    if (isToDo) {
      return new Promise<boolean>((resolve, reject) => {
        translate.get(
          [
            'performerEvaluators.TODO.title',
            'performerEvaluators.TODO.description'
          ])
          .subscribe(translations => {
            const performers: any = {
              TODO: {
                key: "TODO",
                title: translations['performerEvaluators.TODO.title'],
                description: translations['performerEvaluators.TODO.description'],
                minValue: 0,
                maxValue: 0,
                imgSrc: "/assets/img/performer-evaluator/todo.svg"
              }
            };
            resolve(performers);
          })
      })
    } else {
      return new Promise<boolean>((resolve, reject) => {
        translate.get(
          [
            'performerEvaluators.UNSATISFACTONARY_PERFORMER.title',
            'performerEvaluators.UNSATISFACTONARY_PERFORMER.description',
            'performerEvaluators.UNSATISFACTONARY_PERFORMER.abbreviation',
            'performerEvaluators.MARGINAL_PERFORMER.title',
            'performerEvaluators.MARGINAL_PERFORMER.description',
            'performerEvaluators.MARGINAL_PERFORMER.abbreviation',
            'performerEvaluators.STANDARD_PERFORMER.title',
            'performerEvaluators.STANDARD_PERFORMER.description',
            'performerEvaluators.STANDARD_PERFORMER.abbreviation',
            'performerEvaluators.STRONG_PERFORMER.title',
            'performerEvaluators.STRONG_PERFORMER.description',
            'performerEvaluators.STRONG_PERFORMER.abbreviation',
            'performerEvaluators.TOP_PERFORMER.title',
            'performerEvaluators.TOP_PERFORMER.description',
            'performerEvaluators.TOP_PERFORMER.abbreviation',
            'performerEvaluators.TODO.title',
            'performerEvaluators.TODO.description',
            'performerEvaluators.OVERALL.title',
            'performerEvaluators.OVERALL.description',
            'performerEvaluators.VOID_SELF.title',
            'performerEvaluators.VOID_SELF.description'
          ])
          .subscribe(translations => {
            let performers: any = {
              UNSATISFACTONARY_PERFORMER: {
                key: "UNSATISFACTONARY_PERFORMER",
                title: translations['performerEvaluators.UNSATISFACTONARY_PERFORMER.title'],
                abbreviation: translations['performerEvaluators.UNSATISFACTONARY_PERFORMER.abbreviation'],
                description: translations['performerEvaluators.UNSATISFACTONARY_PERFORMER.description'],
                minValue: 0,
                maxValue: 0.99,
                imgSrc: "/assets/img/performer-evaluator/UNSATISFACTONARY_PERFORMER.svg"
              },
              MARGINAL_PERFORMER: {
                key: "MARGINAL_PERFORMER",
                title: translations['performerEvaluators.MARGINAL_PERFORMER.title'],
                abbreviation: translations['performerEvaluators.MARGINAL_PERFORMER.abbreviation'],
                description: translations['performerEvaluators.MARGINAL_PERFORMER.description'],
                minValue: 1,
                maxValue: 1.99,
                imgSrc: "/assets/img/performer-evaluator/MARGINAL_PERFORMER.svg"
              },
              STANDARD_PERFORMER: {
                key: "STANDARD_PERFORMER",
                title: translations['performerEvaluators.STANDARD_PERFORMER.title'],
                abbreviation: translations['performerEvaluators.STANDARD_PERFORMER.abbreviation'],
                description: translations['performerEvaluators.STANDARD_PERFORMER.description'],
                minValue: 2,
                maxValue: 2.99,
                imgSrc: "/assets/img/performer-evaluator/STANDARD_PERFORMER.svg"
              },
              STRONG_PERFORMER: {
                key: "STRONG_PERFORMER",
                title: translations['performerEvaluators.STRONG_PERFORMER.title'],
                abbreviation: translations['performerEvaluators.STRONG_PERFORMER.abbreviation'],
                description: translations['performerEvaluators.STRONG_PERFORMER.description'],
                minValue: 3,
                maxValue: 3.99,
                imgSrc: "/assets/img/performer-evaluator/STRONG_PERFORMER.svg"
              },
              TOP_PERFORMER: {
                key: "TOP_PERFORMER",
                title: translations['performerEvaluators.TOP_PERFORMER.title'],
                abbreviation: translations['performerEvaluators.TOP_PERFORMER.abbreviation'],
                description: translations['performerEvaluators.TOP_PERFORMER.description'],
                minValue: 4,
                maxValue: 4,
                imgSrc: "/assets/img/performer-evaluator/TOP_PERFORMER.svg"
              }
            };

            if (!isSelfView) {
              if (overall) {
                performers = {
                  ...performers,
                  TODO: {
                    key: "OVERALL",
                    title: translations['performerEvaluators.OVERALL.title'],
                    description: translations['performerEvaluators.OVERALL.description'],
                    imgSrc: "/assets/img/performer-evaluator/UNSATISFACTONARY_PERFORMER.svg"
                  }
                }
              } else {
                performers = {
                  ...performers,
                  TODO: {
                    key: "TODO",
                    title: translations['performerEvaluators.TODO.title'],
                    description: translations['performerEvaluators.TODO.description'],
                    imgSrc: "/assets/img/performer-evaluator/UNSATISFACTONARY_PERFORMER.svg"
                  }
                }
              }
            } else {
              performers = {
                ...performers,
                TODO: {
                  key: "VOID_SELF",
                  title: translations['performerEvaluators.VOID_SELF.title'],
                  description: translations['performerEvaluators.VOID_SELF.description'],
                  imgSrc: "/assets/img/performer-evaluator/UNSATISFACTONARY_PERFORMER.svg"
                }
              }
            }
            resolve(performers);
          })
      })
    }
  }
}