import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';

enum kindEnum {
  Star = "star",
  StarLess = "starless"
}


@Component({
  selector: 'competence-evaluation-item',
  templateUrl: './competence-evaluation-item.component.html',
  styleUrls: ['./competence-evaluation-item.component.scss']
})
export class CompetenceEvaluationItemComponent implements OnInit {
  /**
   * @ignore  
   * Percorso dell'img da caricare
   */
  public path!: string;

  /**
   * Aspetto dell'icona 
   */
  @Input() aspect!: "manager" | "collaborator";

  /**
   * Imposta se l'item è cliccabile o meno
   */
  @Input() active: boolean = false;

  /**
   * Imposta la dimensione dell'item
   */
  @Input() size!: "big" | "small";

  /**
   * Imposta il tipo di icona svg da mostare
   */
  @Input() kind!: "star" | "starless";

  /**
  * Rende tutto il componente in readonly
  */
  @Input() readOnly: boolean = false;
  /**
   * Evento scatenato al click sull'item
  */
  @Output() onButtonClicked: EventEmitter<number> = new EventEmitter();

  /**
   * Livello della stella selezionata
   */
  @Input() level!: number;

  /**
   * Possibili dimensioni che il componente può avere, di default è settato a medio(md)
   */
  // @Input() size: "lg" | "md" | "sm" = "md";

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setPath();
  }

  ngOnInit(): void { }

  /**
   * Viene inviato un evento al padre
  */
  emitOnClick(evaluation?: number) {
    if (!evaluation) this.onButtonClicked.emit(this.level);
    else this.onButtonClicked.emit(evaluation);
  }

  private setPath() {
    this.path = '../../../../assets/img/icons/evaluation-item-' + this.level
    if (this.kind == kindEnum.Star) {
      this.path += (this.active ? `-active` : ``) + '.svg';
      return;
    }

    if (this.kind == kindEnum.StarLess) {
      this.path += (this.active ? `-active` : ``) + '.svg';
      return;
    }
  }

  getClass() {
    let base = 'ads-evaluation-item--' + this.size;
    if (this.readOnly) {
      base += ' readOnly';
    }
    return base;
  }

  protected getLevel(level: number) {
    this.onButtonClicked.emit(level)
  }
}
