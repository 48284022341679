<div [ngClass]="'ads-evaluation-system--direction-' + direction" *ngIf="!isNotApplicable; else notApplicable">
    <div class="ads-evaluation-system__container-star" [ngClass]="{'secondary': secondaryColor}">
        <ng-container *ngFor="let key of keys;">
            <ng-container *ngIf="key != -1">
                <competence-evaluation-item [active]="newObject[key].active" [size]="size" [aspect]="aspect"
                    [readOnly]="readOnly" [kind]="newObject[key].disabled ? 'starless' : newObject[key].kind"
                    [level]="key" (onButtonClicked)="emitOnClick($event)">
                </competence-evaluation-item>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="canValueNA">
            <competence-evaluation-item [active]="newObject[-1].active" [size]="size" [aspect]="aspect"
                [readOnly]="readOnly" [kind]="newObject[-1].disabled ? 'starless' : newObject[-1].kind" [level]="-1"
                (onButtonClicked)="emitOnClick($event)">
            </competence-evaluation-item>
        </ng-container>
    </div>

    <div class="ads-evaluation-system__footer-message-title" [ngSwitch]="level" *ngIf="showTitles"
        [ngClass]="{'ads-evaluation-system__footer-message-subtitle': titleStyleAsDescription}">
        <!-- Titoli scala per competenze -->
        <ng-container *ngIf="competenceScale; else objectiveTitleScale">
            <span *ngSwitchCase="-1">{{valueNA ? valueNA : ('evaluationsItems.competences.N_A' | translate)}}</span>
            <span *ngSwitchCase="1">{{valueFirstStar ? valueFirstStar : ('evaluationsItems.competences.0' |
                translate)}}</span>
            <span *ngSwitchCase="2">{{valueSecondStar ? valueSecondStar : ('evaluationsItems.competences.1' |
                translate)}}</span>
            <span *ngSwitchCase="3">{{valueThirdStar ? valueThirdStar : ('evaluationsItems.competences.2' |
                translate)}}</span>
            <span *ngSwitchCase="4">{{valueFourthStar ? valueFourthStar : ('evaluationsItems.competences.3' |
                translate)}}</span>
            <span *ngSwitchCase="5">{{valueFifthStar ? valueFifthStar : ('evaluationsItems.competences.4' |
                translate)}}</span>
        </ng-container>
        <!-- Titoli scala per obiettivi -->
        <ng-template #objectiveTitleScale>
            <span *ngSwitchCase="-1">{{valueNA ? valueNA : ('evaluationsItems.objectives.N_A' | translate)}}</span>
            <span *ngSwitchCase="1">{{valueFirstStar ? valueFirstStar : ('evaluationsItems.objectives.0' |
                translate)}}</span>
            <span *ngSwitchCase="2">{{valueSecondStar ? valueSecondStar : ('evaluationsItems.objectives.1' |
                translate)}}</span>
            <span *ngSwitchCase="3">{{valueThirdStar ? valueThirdStar : ('evaluationsItems.objectives.2' |
                translate)}}</span>
            <span *ngSwitchCase="4">{{valueFourthStar ? valueFourthStar : ('evaluationsItems.objectives.3' |
                translate)}}</span>
            <span *ngSwitchCase="5">{{valueFifthStar ? valueFifthStar : ('evaluationsItems.objectives.4' |
                translate)}}</span>
        </ng-template>

    </div>
    <div class="ads-evaluation-system__footer-message-subtitle" [ngSwitch]="level" *ngIf="showDescr">
        <!-- Descrizione scala competenze -->
        <ng-container *ngIf="competenceScale; else objectiveScaleDescr">
            <span *ngSwitchCase="-1">{{valueNASub ? valueNASub : ('evaluationsItems.competences.N_A_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="1">{{valueFirstStarSub ? valueFirstStarSub : ('evaluationsItems.competences.0_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="2">{{valueSecondStarSub ? valueSecondStarSub : ('evaluationsItems.competences.1_DESCR'
                | translate)}}</span>
            <span *ngSwitchCase="3">{{valueThirdStarSub ? valueThirdStarSub : ('evaluationsItems.competences.2_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="4">{{valueFourthStarSub ? valueFourthStarSub : ('evaluationsItems.competences.3_DESCR'
                | translate)}}</span>
            <span *ngSwitchCase="5">{{valueFifthStarSub ? valueFifthStarSub : ('evaluationsItems.competences.4_DESCR' |
                translate)}}</span>
        </ng-container>
        <!-- Descrizione scala obiettivi -->
        <ng-template #objectiveScaleDescr>
            <span *ngSwitchCase="-1">{{valueNASub ? valueNASub : ('evaluationsItems.objectives.N_A_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="1">{{valueFirstStarSub ? valueFirstStarSub : ('evaluationsItems.objectives.0_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="2">{{valueSecondStarSub ? valueSecondStarSub : ('evaluationsItems.objectives.1_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="3">{{valueThirdStarSub ? valueThirdStarSub : ('evaluationsItems.objectives.2_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="4">{{valueFourthStarSub ? valueFourthStarSub : ('evaluationsItems.objectives.3_DESCR' |
                translate)}}</span>
            <span *ngSwitchCase="5">{{valueFifthStarSub ? valueFifthStarSub : ('evaluationsItems.objectives.4_DESCR' |
                translate)}}</span>
        </ng-template>
    </div>
</div>

<ng-template #notApplicable>
    <p class="not-available">N/A</p>
</ng-template>