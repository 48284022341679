import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'card-feedback-header',
  templateUrl: './card-feedback-header.component.html',
  styleUrls: ['./card-feedback-header.component.scss']
})
export class CardFeedbackHeaderComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() creationDate: string = '';
  @Input() hasAvatar: boolean = false;
  @Input() avatarRight: boolean = false;
  @Input() type: 'completed' | 'inProgress' | 'pending' | 'toStart' | 'disabled' | '' = '';
  @Input() user: any;

  constructor() { }

  ngOnInit(): void {
  }

}
