import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfAlloy360Feedback } from 'atfcore-commonclasses';
import moment from 'moment';

@Component({
  selector: 'card-360',
  templateUrl: './card-360.component.html',
  styleUrls: ['./card-360.component.scss']
})
export class Card360Component implements OnInit {
  @Input() object!: PerfAlloy360Feedback;
  @Input() loggedUser?: any;
  @Input() feedbackStatus!: string;
  @Output() onDetailsClicked: EventEmitter<any> = new EventEmitter();

  commentDateText: string = '';
  isPosted: boolean = false;
  responseTitle: string = '';

  constructor(
    private translate: TranslateService
  ) { }


  ngOnInit(): void {
    this.commentDateText = this.object.commentDate ? moment(this.object.commentDate).fromNow() : '--';
    this.responseTitle = this.translate.instant('feedbackSection.360_FEEDBACK_OF') + '<b> ' + this.object.intermediateUser?.surname + ' ' + this.object.intermediateUser?.forename;
  }

  emitOnDetailsClicked(feedbackId: string) {
    this.onDetailsClicked.emit(feedbackId);
  }
}
