<div class="user-card-wrapper" *ngIf="user" (click)="!forceDisabled && !noActions && emitOnCheck()"
  [ngClass]="{'active': user.isChecked && !noActions, 'noActions': noActions, 'force-disable': forceDisabled}">
  <avatar-img [initials]="user.initials" [src]="user.avatar" size="medium"></avatar-img>
  <div class="name-wrapper" [ngClass]="{'row': isFeedbackCard}">
    <p *ngIf="!fromCppCollabs" class="text-default">{{ user.surname }}</p>
    <p *ngIf="!fromCppCollabs" class="text-default">{{ user.name }}</p>
    <p *ngIf="fromCppCollabs" class="text-default">{{ user.surname }} {{ user.name }}</p>
    <p *ngIf="fromCppCollabs" class="text-default">{{ user.role }}</p>
    <ng-container *ngIf="user.cardStatus">
      <item-list-team-status [message]="'upwardFeedback.cardStatus.' + user.cardStatus | translate"
        [type]="user.cardStatus" styleItem="border">
      </item-list-team-status>
    </ng-container>
  </div>
  <div class="check-wrapper" *ngIf="!noActions" [ngClass]="{ 'disabled': user?.disabled }">
    <svg-icon *ngIf="user.isChecked" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true">
    </svg-icon>
    <div *ngIf="radio && user.isChecked" class="checked"></div>
  </div>

  <svg-icon *ngIf="noActions" class="trash" (click)="emitOnDelete()" src="assets/img/icons/trash-2.svg"
    [applyClass]="true">
  </svg-icon>
</div>