<div class="alloy-button" [ngClass]="type + ' ' + size + ' ' + (disabled ? 'disabled': '' + mobileShrink ? 'mobile-shrink' : '')"
  (click)="emitOnClick($event)">
  <ng-container *ngIf="iconLeft">
    <div class="left-icon-wrapper" *ngIf="checkIconLeft()">
      <svg-icon [ngClass]="iconLeftClass" *ngIf="iconLeft" [src]="iconLeft" class="medium-size" [applyClass]="true">
      </svg-icon>
    </div>
  </ng-container>
  <p>{{label}}</p>
  <ng-container *ngIf="iconRight">
    <div class="right-icon-wrapper" *ngIf="checkIconRight()">
      <svg-icon *ngIf="iconRight" [src]="iconRight" class="medium-size" [applyClass]="true"></svg-icon>
    </div>
  </ng-container>
</div>