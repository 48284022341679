import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent {
  @Input() valueList: Array<{ title: string, first: number, second: number, third: number }> = [{ title: '', first: 0, second: 0, third: 0 }];

  screenWidth: number = 0;
  isMobile: boolean = false;

  constructor() {
    if (window.innerWidth >= 1000) {
      this.screenWidth = 490;
    } else if (window.innerWidth < 600) {
      this.screenWidth = window.innerWidth - 150;
      this.isMobile = true;
    } else {
      this.screenWidth = window.innerWidth - 325;
      this.isMobile = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    if (event.target.innerWidth >= 1000) {
      this.screenWidth = 490;
      this.isMobile = false;
    } else if (event.target.innerWidth < 600) {
      this.screenWidth = window.innerWidth - 150;
      this.isMobile = true;
    } else {
      this.screenWidth = event.target.innerWidth - 325;
      this.isMobile = true;
    }


  }
  ngOnInit() {
  }
}
