<div class="tag-container">
    <div class="divisor-line-goal"></div>
    <div class="text-goal">
        <!-- Titolo -->
        <p class="tag" translate="defineNewObjectives.OBJECTIVE"></p>
        <!-- Descrizione -->
        <p class="desc"> {{desc}}</p>
        <!-- Inserito da -->
        <p *ngIf="insertBy" class="insertBy" [innerHtml]="('textGoal.INSERTED_BY' | translate)+ ' <b>' + insertBy"></p>
    </div>

    <div *ngIf="!hideMenu && menuOptions.length" class=" menu-btn" (clickOutside)="isOpen = false;">
        <p *ngIf="isNew" class="new-badge" translate="generic.NEW"></p>
        <svg-icon src="assets/img/icons/more-vertical.svg" [applyClass]="true" class="menu-icon" (click)="toggleMenu()">
        </svg-icon>
        <!-- Dropdown -->
        <ng-container *ngIf="isOpen">
            <div class="dropdown-menu">
                <ng-container *ngFor="let option of menuOptions; let first = first; let last = last">
                    <alloy-dropdown-item [ngClass]="{'first': first, 'last': last}"
                        (click)="onMenuOptionClicked(option.id)" class="full-width" [item]="option">
                    </alloy-dropdown-item>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>