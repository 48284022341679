/*
 * Componente per la pagina di supporto
*/

import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as PeopleAppraisalActions from "../../peopleAppraisal/ngrx/peopleAppraisal.actions"
import { ApplicationModalMessage } from '../ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subordinateservice } from 'src/app/peopleAppraisal/services/subordinates.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnDestroy {
  isImpersonate: boolean = false;
  currentYear = new Date().getFullYear();

  subordinatesWithPhaseStatuses$: any;
  runningYear$: any;
  runningYear: any;
  isSelfAssessment: boolean = false;
  loggedUser: any;
  getLoggedUser$: any;
  getMySubordinateUserData$: any;
  materialData: { title: any; src: string; } | null = null;
  isLoadingMySubordinateUserData: boolean = false;
  isPeopleAppraisal: boolean = false;
  yearsWithSpecialisticGoalsToEvaluate: boolean = false;
  techUser: any;
  defaultUser: any;
  showTechStep: boolean = false;
  showCompetenceStep: boolean = false;
  userIdsForTutorial: any;
  has3Steps: boolean = false;

  constructor(
    private sharedService: SharedService,
    public router: Router,
    private translate: TranslateService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private subordinateService: Subordinateservice
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Supporto");

    this.isSelfAssessment = window.location.href.indexOf('selfAssessment') > -1;
    this.isPeopleAppraisal = window.location.href.indexOf('peopleAppraisal') > -1;

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;

      if (this.runningYear && this.runningYear.year) {
        this.getLoggedUser$ = this.store.select(fromApp.getLoggedUser)
          .subscribe(
            (loggedUser) => {
              this.loggedUser = loggedUser && loggedUser.user;
              if (this.isPeopleAppraisal && !this.isLoadingMySubordinateUserData) {
                this.isLoadingMySubordinateUserData = true;
                // this.getSubordinateUserDataForManager();
              } else if (!this.isPeopleAppraisal && loggedUser) {
                this.isLoadingMySubordinateUserData = true;
                // this.isThisSpecialTechYear(loggedUser.params.yearsWithSpecialisticGoalsToEvaluate);
                // this.getSubordinateUserData();
              }
            });
      }
    });
  }

  // Apre il tutorial dell'home page
  openHomePageTutorial() {
    this.redirectService.openHomePageTutorial();
  }

  // Simula il back del browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  openPersonDetailsPage(userId?: string) {
    this.store.dispatch(PeopleAppraisalActions.SetHeaderTeamClickedButton({ payload: false }));
    if (userId || this.isSelfAssessment) {
      let baseUrl = '';
      const selectedUserId = userId || (this.loggedUser && this.loggedUser.userId);

      if (this.isSelfAssessment) {
        baseUrl = 'selfAssessment/personDetails/';
      } else {
        baseUrl = 'peopleAppraisal/personDetails/';
        this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: selectedUserId }));
      }
      this.router.navigate([baseUrl, selectedUserId, this.runningYear.year, this.runningYear.yearCode]);
    }
  }

  // Apre una modale indicante che il tutorial non è disponibile
  openNotAvailableTutorialModal() {
    const messageObj: ApplicationModalMessage = {
      modalId: "171",
      text: this.translate.instant("support.NO_AVAILABLE_TUTORIAL"),
      title: this.translate.instant("generic.WARNING")
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Apre la struttura della scheda pas
  openPasStructure() {
    if (this.isSelfAssessment || (this.userIdsForTutorial && this.userIdsForTutorial.goalToEvaluateUserId)) {
      sessionStorage.setItem('openPasStructureTutorial', 'true');
      this.openPersonDetailsPage(this.isSelfAssessment ? this.loggedUser.userId : this.userIdsForTutorial.goalToEvaluateUserId);
    } else {
      this.openNotAvailableTutorialModal();
    }
  }

  // Apre la valutazione obiettivi di sviluppo personale
  openPasStep1() {
    if (this.isSelfAssessment || (this.userIdsForTutorial.goalToEvaluateUserId && this.userIdsForTutorial.goalToEvaluateUserId.length)) {
      sessionStorage.setItem('openPersonalObjectiveEvaluationTutorial', 'true');
      this.openPersonDetailsPage(this.isSelfAssessment ? this.loggedUser.userId : this.userIdsForTutorial.goalToEvaluateUserId);
    } else {
      this.openNotAvailableTutorialModal();
    }
  }

  // Apre la valutazione comportamenti organizzativi
  openPasStep2() {
    if (this.isSelfAssessment || (this.userIdsForTutorial.behaviorToEvaluateUserId && this.userIdsForTutorial.behaviorToEvaluateUserId.length)) {
      sessionStorage.setItem('openOrganizationalBehaviorsTutorial', 'true');
      this.openPersonDetailsPage(this.isSelfAssessment ? this.loggedUser.userId : this.userIdsForTutorial.behaviorToEvaluateUserId);
    } else {
      this.openNotAvailableTutorialModal();
    }
  }

  // Apre la valutazione delle competenze tecniche
  openPasStep3(isTech?: boolean) {
    if (this.isSelfAssessment || (this.showTechStep && this.userIdsForTutorial.specialisticGoalUserId && this.userIdsForTutorial.specialisticGoalUserId.length) || (this.showCompetenceStep && this.userIdsForTutorial.competenceToEvaluateUserId && this.userIdsForTutorial.competenceToEvaluateUserId.length)) {
      sessionStorage.setItem('openTechSkillEvaluationTutorial', 'true');
      if (isTech) {
        this.openPersonDetailsPage(this.isSelfAssessment ? this.loggedUser.userId : this.userIdsForTutorial.specialisticGoalUserId);
      } else {
        this.openPersonDetailsPage(this.isSelfAssessment ? this.loggedUser.userId : this.userIdsForTutorial.competenceToEvaluateUserId);
      }
    } else {
      this.openNotAvailableTutorialModal();
    }
  }

  // Apre la definizione di nuovi obiettivi
  openPasStep4() {
    if (this.isSelfAssessment || (this.userIdsForTutorial.goalToDefineUserId && this.userIdsForTutorial.goalToDefineUserId.length)) {
      sessionStorage.setItem('openSetGoalsTutorial', 'true');
      this.openPersonDetailsPage(this.isSelfAssessment ? this.loggedUser.userId : this.userIdsForTutorial.goalToDefineUserId);
    } else {
      this.openNotAvailableTutorialModal();
    }
  }

  getSubordinateUserDataForManager() { // TODO cambiare e prendere lista utenti per vedere se c'è utente con obiettivi tecnico specialistici
    this.isLoadingMySubordinateUserData = true;

    if (this.getMySubordinateUserData$) {
      this.getMySubordinateUserData$.unsubscribe();
    }
    this.getMySubordinateUserData$ = this.subordinateService.getUserRedirectForGuidedTourForManager(this.runningYear.year, this.runningYear.yearCode)
      .subscribe((res: any) => {
        if (res && res.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn017",
            text: this.translate.instant("errors." + res.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.userIdsForTutorial = res.response;
          if (this.userIdsForTutorial.specialisticGoalUserId) {
            this.showTechStep = true;
          }
          if (this.userIdsForTutorial.competenceToEvaluateUserId) {
            this.showCompetenceStep = true;
          }
          if (!this.showTechStep && !this.showCompetenceStep) {
            this.has3Steps = true;
          }
          this.isLoadingMySubordinateUserData = false;
        }
      }, (err?: any) => {
        this.isLoadingMySubordinateUserData = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }


  getSubordinateUserData() { // TODO cambiare e prendere lista utenti per vedere se c'è utente con obiettivi tecnico specialistici
    this.isLoadingMySubordinateUserData = true;

    if (this.getMySubordinateUserData$) {
      this.getMySubordinateUserData$.unsubscribe();
    }

    this.getMySubordinateUserData$ = this.subordinateService.getUserRedirectForGuidedTourForSubordinate(this.runningYear.year, this.runningYear.yearCode)
      .subscribe((res: any) => {
        if (res && res.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn017",
            text: this.translate.instant("errors." + res.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.userIdsForTutorial = res.response;
          if (this.userIdsForTutorial.specialisticGoalUserId) {
            this.showTechStep = true;
          }
          if (this.userIdsForTutorial.competenceToEvaluateUserId) {
            this.showCompetenceStep = true;
          }
          if (!this.showTechStep && !this.showCompetenceStep) {
            this.has3Steps = true;
          }
          this.isLoadingMySubordinateUserData = false;
        }
      }, (err?: any) => {
        this.isLoadingMySubordinateUserData = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  isThisSpecialTechYear(techYears: any) {
    if (techYears && techYears.length) {
      for (let i = 0, yearsLength = techYears.length; i < yearsLength; i++) {
        const currentSpecialYear = techYears[i];

        if (currentSpecialYear.year && currentSpecialYear.year === this.runningYear.year.toString() && currentSpecialYear.yearCode === this.runningYear.yearCode) {
          this.yearsWithSpecialisticGoalsToEvaluate = true;
          break;
        }
      }
    }
  }

  // Apre il materiale video
  showMaterial(num?: number) {
    this.materialData = {
      title: '',
      src: ''
    };

    switch (num) {
      case 1: {
        this.materialData.title = this.translate.instant('support.materials.METHOD_DEFINITION');
        if (this.isSelfAssessment) {
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/c9231339-40af-41bd-b78a-1e95d28d32c0/01 PAS DEFINIZIONE E METODO.ism/manifest(format=m3u8-cmaf)";
        } else {
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/e7a4d549-783f-4a63-b37b-040f4032a70e/01 VALUTATORI - DEFINIZIONE E ME.ism/manifest(format=m3u8-cmaf)";
        }
        break;
      }
      case 2: {
        if (this.isSelfAssessment) {
          this.materialData.title = this.translate.instant('support.materials.SELF_ASS');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/dd7b618d-7c08-40f9-a8d5-676122e53d38/02 PAS SELF ASSESSMENT.ism/manifest(format=m3u8-cmaf)";
        } else {
          this.materialData.title = this.translate.instant('support.materials.POSSIBLE_SCENARIOS');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/dac84835-cf71-4cbe-ab0f-4bfc02b22ecb/02 VALUTATORI - POSSIBILI SCENAR.ism/manifest(format=m3u8-cmaf)";
        }
        break;
      }
      case 3: {
        if (this.isSelfAssessment) {
          this.materialData.title = this.translate.instant('support.materials.SELF_RISKS');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/83613601-cc2f-4845-9914-0f07e872e631/03 PAS RISCHI DEL SELF ASSESSMEN.ism/manifest(format=m3u8-cmaf)";
        } else {
          this.materialData.title = this.translate.instant('support.materials.EVALUATION_RISKS');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/f5c99285-edf0-47c2-ba56-471aabf63162/03 VALUTATORI - RISCHI DELLA VAL.ism/manifest(format=m3u8-cmaf)";
        }
        break;
      }
      case 4: {
        if (this.isSelfAssessment) {
          this.materialData.title = this.translate.instant('support.materials.SELF_FEEDBACK');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/93b80326-6765-41f2-8bce-2ce59e5bc035/04 PAS FEEDBACK EFFICACE.ism/manifest(format=m3u8-cmaf)";
        } else {
          this.materialData.title = this.translate.instant('support.materials.EFFECTIVE_FEEDBACK');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/99cd3488-00f5-4a02-9d72-84c50dc0000b/04 VALUTATORI - IL FEEDBACK EFFI.ism/manifest(format=m3u8-cmaf)";
        }
        break;
      }
      case 5: {
        if (this.isSelfAssessment) {
          this.materialData.title = this.translate.instant('support.materials.CONSTRUCTIVE_FEEDBACK');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/b41f5044-d3bd-4022-8bae-ca1a7491b040/05 PAS APPROCCIO COSTRUTTIVO AL .ism/manifest(format=m3u8-cmaf)";
        } else {
          this.materialData.title = this.translate.instant('support.materials.PREP_ORG');
          this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/794c4bcf-5e31-4449-aa91-2f66bfb63167/05 VALUTATORI - PREP ORG REST.ism/manifest(format=m3u8-cmaf)";
        }
        break;
      }
      case 6: {
        this.materialData.title = this.translate.instant('support.materials.COMMUNICATION_TECH');
        this.materialData.src = "https://alloysharedmediaservice-euwe.streaming.media.azure.net/5be8b8d6-934b-4af8-b244-cc829675054f/06 VALUTATORI - TECNICHE DI COMU.ism/manifest(format=m3u8-cmaf)";
        break;
      }
    }

    setTimeout(() => {
      this.modalService.open('material-modal');
    }, 500);
  }

  closeMaterialModal() {
    this.modalService.close('material-modal');
    this.materialData = null;
  }

  ngOnDestroy(): void {
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getLoggedUser$) {
      this.getLoggedUser$.unsubscribe();
    }
    if (this.getMySubordinateUserData$) {
      this.getMySubordinateUserData$.unsubscribe();
    }
  }
}
