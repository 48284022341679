import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscriber } from 'rxjs';
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'circle-percentage',
  templateUrl: 'circle-percentage.component.html',
  styleUrls: ['./circle-percentage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CirclePercentageComponent implements OnInit {
  @Input() title: string = '';
  @Input() percentageCircle: number = 0;
  @Input() isFromSidebar: boolean = false;
  @Input() isFromHeaderDossier: boolean = false;
  @Input() isButtonActive: boolean = false;
  percentage: string = '';

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    this.percentageCircle = Number(this.title)
    this.percentage = (180 * this.percentageCircle / 100) + 'deg';
    if (this.percentageCircle !== 100 && (this.isFromSidebar || this.isFromHeaderDossier)) {
      this.title = this.title + '%';
    }
  }

}