import { Component, Input } from '@angular/core';

@Component({
  selector: 'pce-dropdown',
  templateUrl: './pce-dropdown.component.html',
  styleUrls: ['./pce-dropdown.component.scss']
})
export class PceDropdownComponent {
  /** Apre/chiude il dropdown */
  @Input() active?: boolean;
  /** In caso sia true il componente viene posizionato in maniera assoluta rispetto al padre */
  @Input() absolute?: boolean;
  @Input() direction: "horizontal" | "vertical" = "horizontal";
}
