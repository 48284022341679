<div class="component-container">
  <!-- Header dossier per utenti e manager -->
  <ng-container *ngIf="!isAdminHeader">
    <div class="info-container">
      <div class="data-container">
        <div class="user-container">
          <!-- Informazioni utente -->
          <div class="user" *ngIf="user" id="userAvatar">
            <avatar-img size="big" [src]="user.userOptions.avatarImage" [user]="user" [initials]="user | userInitials">
            </avatar-img>
            <div class="info">
              <!-- Titolo pagina o username -->
              <div class="user-name">
                <p class="text-title" *ngIf="!isDashboard && !isFeedback360" translate="header.PAS"></p>
                <p class="text-title" *ngIf="isDashboard" translate="header.DASHBOARD"></p>
                <p class="text-title" *ngIf="isFeedback360" translate="header.FEEDBACK_360"></p>
                <p class="text-description">{{user.surname}} {{user.forename}}</p>
              </div>
              <!-- Select con elenco anni del processo -->
              <ng-container *ngIf="selectedYear">
                <ng-select class="header-dossier-select" [disabled]="disabled" [clearable]="false" [multiple]="false"
                  [searchable]="false" [(ngModel)]="selectedYear" [items]="yearItems"
                  placeholder="{{ ('goalSetting.SELECT_YEAR' | translate) }}" (change)="emitOnSelectedYearItem($event)">

                  <!-- Elementi selezionati -->
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <p>{{item.name}}</p>
                  </ng-template>

                  <!-- Template per l'inizio della ricerca -->
                  <ng-template ng-typetosearch-tmp>
                    <div class="ng-option disabled">{{ ('generic.START_SEARCH' | translate) }}</div>
                  </ng-template>
                  <!-- Template per i dati non trovati -->
                  <ng-template ng-notfound-tmp let-searchTerm="filter">
                    <div class="ng-option disabled">
                      <alloy-dropdown-item class="full-width" [item]='noDataObject'>
                      </alloy-dropdown-item>
                    </div>
                  </ng-template>
                  <!-- Template per i singoli item nelle options -->
                  <ng-template ng-option-tmp let-item="item" let-search="filter">
                    <alloy-dropdown-item class="full-width" [item]="item"></alloy-dropdown-item>
                  </ng-template>
                </ng-select>
              </ng-container>
              <!-- Select con elenco round del feedback 360 -->
              <ng-container *ngIf="selectedRound">
                <ng-select class="header-dossier-select feedback" [disabled]="disabled" [clearable]="false"
                  [multiple]="false" [searchable]="false" [(ngModel)]="selectedRound" [items]="roundList"
                  placeholder="{{ ('goalSetting.SELECT_YEAR' | translate) }}" (change)="emitOnSelectedRound($event)">

                  <!-- Elementi selezionati -->
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <p>{{item.name}}</p>
                  </ng-template>

                  <!-- Template per l'inizio della ricerca -->
                  <ng-template ng-typetosearch-tmp>
                    <div class="ng-option disabled">{{ ('generic.START_SEARCH' | translate) }}</div>
                  </ng-template>
                  <!-- Template per i dati non trovati -->
                  <ng-template ng-notfound-tmp let-searchTerm="filter">
                    <div class="ng-option disabled">
                      <alloy-dropdown-item class="full-width" [item]='noDataObject'>
                      </alloy-dropdown-item>
                    </div>
                  </ng-template>
                  <!-- Template per i singoli item nelle options -->
                  <ng-template ng-option-tmp let-item="item" let-search="filter">
                    <alloy-dropdown-item class="full-width" [item]="item"></alloy-dropdown-item>
                  </ng-template>
                </ng-select>
              </ng-container>
            </div>
          </div>
          <!-- 
          <div class="logo">
            <div class="logo-container">
              <svg-icon class="small-logo" [applyClass]="true" src="./assets/img/icons/facile-it-logo.svg">
              </svg-icon>
            </div>
          </div>
          -->
        </div>
        <div class="button-container">
          <ng-container *ngIf="!hideAction && !availibleNotes">
            <div id="takeNoteBtn" class="button-create-interview" *ngIf="!isDashboard">
              <alloy-button type="primary fill" size="medium" label="{{'header.TAKE_NOTE' | translate}}"
                (click)="emitOnOrganizeinterview()">
              </alloy-button>
            </div>
            <div class="button-create-interview" *ngIf="isDashboard">
              <alloy-button type="primary fill" size="medium" label="{{'dashboard.DOWNLOAD_REPORT' | translate}}"
                (click)="emitOnOrganizeinterview()">
              </alloy-button>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- Da ripristinare se chiedono i colloqui -->
      <!--div class="notify-wrapper" *ngIf="interview">
        <div class="notify-container">
          <div class="interview-date">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="3" cy="3" r="3" fill="#FC5A60" />
            </svg>
            <p *ngIf="interview.isEditabled">{{('goalSetting.YOU_ORGANIZED_INTERVIEW_FOR' | translate) +
              (interview.startDate | date: 'dd/MM/YYYY')}}
            </p>
            <p *ngIf="!interview.isEditabled">{{('goalSetting.IT_ORGANIZED_INTERVIEW_FOR' | translate) +
              (interview.startDate | date: 'dd/MM/YYYY')}}
            </p>
          </div>
          <a *ngIf="interview.isEditabled" translate="generic.ANNULL_INTERVIEW"
            (click)="emitOnAnnullInterview(interview.feedbackDialogueId)"></a>
        </div>
      </div-->
    </div>
    <ng-container *ngIf="hasTabs">
      <div class="buttons-tab-container">
        <div class="tab-container">
          <div id="{{ item.id }}" class="button" (click)="menuButtonClicked(item)" *ngFor="let item of menuButtons"
            [ngClass]="{'active': item.id == selectedMenuButton.id, 'disabled': item.isDisabled}">
            <p class="text-button">{{ item.name }}&nbsp;
              <span *ngIf="item.count">{{'(' + item.count + ')'}}</span>
            </p>
            <badge-notifications *ngIf="item.badgeNumber" [badgeNumber]="item.badgeNumber" type="active">
            </badge-notifications>
            <svg-icon *ngIf="item.id == selectedMenuButton.id" class="small-size arrow-down-mobile show-xs"
              [applyClass]="true" src="./assets/img/icons/chevron-down-white.svg"></svg-icon>
          </div>
          <div *ngIf="mobileMenuOpened" class="menu-container-mobile">
            <alloy-dropdown-item *ngFor="let item of mobileMenuItems" [item]="item" (click)="emitOnOptionClicked(item)">
            </alloy-dropdown-item>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- admin -->
  <ng-container *ngIf="isAdminHeader">
    <div class="admin-container">
      <div class="left-section" *ngIf="adminBack">
        <div>
          <alloy-button iconLeft="assets/img/icons/arrow-left.svg" [label]="('generic.GO_BACK' | translate)"
            (onButtonClicked)="redirectService.goBackBrowser()" type="trasparent" size="medium">
          </alloy-button>
        </div>
      </div>
      <div class="center-section">
        <p class="admin-area" *ngIf="!isDashboard" translate="generic.ADMIN_AREA"></p>
        <p class="title">{{adminPageTitle }}</p>
        <p class="subtitle">{{adminPageSubtitle}}</p>
      </div>
      <div class="right-section">
        <!-- <ng-container *ngIf="showLoader">
          <div class="loader spinner-small"></div>
        </ng-container> -->
        <ng-container *ngIf="adminActionLabel && adminActionLabel.length && !isAdminMenu">
          <!-- <alloy-button [disabled]="adminActionDisabled" [iconRight]="adminActionIcon" [label]="adminActionLabel"
            (onButtonClicked)="emitOnAdminAction()" [type]="adminActionLabelType" size="medium">
          </alloy-button> -->
          <alloy-button size="medium" [type]="'primary fill'" [label]="adminActionLabel"
            (onButtonClicked)="emitOnAdminAction()"></alloy-button>
        </ng-container>
        <ng-container *ngIf="isAdminMenu">
          <div class="actions-container">
            <div class="action">
              <alloy-button size="medium" [disabled]="showLoader" [type]="'primary fill'"
                [label]="'headerDossier.DOWNLOAD_REPORT' | translate" (onButtonClicked)="openAdminMenu()"
                (clickOutside)="openAdminMenu(true)" [ngClass]="{'opened': isOpenedAdminMenu, 'loading':showLoader}"
                [iconRight]="'assets/img/icons/chevron-down-small.svg'"></alloy-button>
              <ng-container *ngIf="isOpenedAdminMenu">
                <div class="dropdown-menu-table">
                  <ng-container *ngFor="let item of menuOptions">
                    <alloy-dropdown-item class="full-width" [disabled]="showLoader" [item]="item"
                      (click)="emitOnAdminAction(item.id)">
                    </alloy-dropdown-item>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="counter">
          <div class="counter-container">
            <p class="count">{{counter}}</p>
            <p class="label">{{counterLabel}}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="hasTabs">
      <div class="buttons-tab-container process-setup">
        <div class="tab-container">
          <div id="{{ item.id }}" class="button" (click)="menuButtonClicked(item)" *ngFor="let item of menuButtons"
            [ngClass]="{'active': item.id == selectedMenuButton.id, 'disabled': item.isDisabled}">
            <p class="text-button">{{item.name}}</p>
            <badge-notifications *ngIf="item.badgeNumber" [badgeNumber]="item.badgeNumber" type="active">
            </badge-notifications>
            <svg-icon *ngIf="item.id == selectedMenuButton.id" class="small-size arrow-down-mobile show-xs"
              [applyClass]="true" src="./assets/img/icons/chevron-down.svg"></svg-icon>
          </div>
          <div *ngIf="mobileMenuOpened" class="menu-container-mobile">
            <alloy-dropdown-item *ngFor="let item of mobileMenuItems" [item]="item" (click)="emitOnOptionClicked(item)">
            </alloy-dropdown-item>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>