<textarea *ngIf="!readonly && !upwardDisabled" [(ngModel)]="bindValue" [placeholder]="placeholder"
  attr.aria-label="{{ attrAriaLabel }}" (ngModelChange)="emitOnModelChanged($event)" #textareaEl="ngModel" [ngClass]="{'white': white}">{{bindValue}}</textarea>

<p *ngIf="readonly && !isInnerHTML">
  {{bindValue}}
</p>

<p [innerHTML]="bindValue" *ngIf="readonly && isInnerHTML">
</p>

<div *ngIf="canCancelEdit" class="full-width">
  <p class="cancel-edit-container">
    <span class="link" translate="goalSetting.createGoal.SAVE_GOAL" (click)="saveEdit.emit()"></span>
    <span class="link" translate="generic.ANNULL" (click)="cancelEdit.emit()"></span>
  </p>
</div>
