import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'team-sidebar',
  templateUrl: './team-sidebar.component.html',
  styleUrls: ['./team-sidebar.component.scss'],
})
export class TeamSidebarComponent {
  @Input() users?: any[];
  @Input() activeUserId: string | null = null;
  @Input() isLoadingUsers: boolean = false;
  @Input() hasImpersonateBanner: boolean = false;
  @Input() isOpenedSecondLevel: boolean = false;
  @Input() structureUsers?: any[];
  @Input() currentPhaseId: string = '';
  @Input() showFeedbackStatus: boolean = false;
  @Output() userClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondLevelOpen: EventEmitter<any> = new EventEmitter<any>();

  searchedText: string | null = null;
  searchResults: any[] = [];
  searchStructureResults: any[] = [];
  constructor(
    public cdr: ChangeDetectorRef,
  ) {
  }

  onUserSearch(text: any) {
    if (this.users && this.searchedText) {
      let tempSearch = this.searchedText.toLowerCase();
      this.searchResults = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].forename && this.users[i].surname &&
          (this.users[i].forename.toLowerCase().indexOf(tempSearch) != -1 || this.users[i].surname.toLowerCase().indexOf(tempSearch) != -1)) {
          this.searchResults.push(this.users[i]);
        }
      }
    } else {
      this.searchResults = [];
    }

    if (this.structureUsers && this.searchedText) {
      let tempSearch = this.searchedText.toLowerCase();
      this.searchStructureResults = [];
      for (var i = 0; i < this.structureUsers.length; i++) {
        if (this.structureUsers[i].forename && this.structureUsers[i].surname &&
          (this.structureUsers[i].forename.toLowerCase().indexOf(tempSearch) != -1 || this.structureUsers[i].surname.toLowerCase().indexOf(tempSearch) != -1)) {
          this.searchStructureResults.push(this.structureUsers[i]);
        }
      }
    } else {
      this.searchStructureResults = [];
    }
  }

  onUserClicked(user: any) {
    this.userClicked.emit(user);
    this.cdr.detectChanges();
  }

  onSecondLevelOpen() {
    this.secondLevelOpen.emit(!this.isOpenedSecondLevel);
  }

  userId(index: number, el: any) {
    return el.userId;
  }

  isInPersonDetails() {
    return window.location.href.indexOf('personDetails') >= 0;
  }

}
