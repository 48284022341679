<div class="main-container" (click)="emitOnClick()">
    <!-- Icona a sinistra -->
    <div class="icon-container">
        <svg-icon [src]="icon"></svg-icon>
    </div>
    <div class="content-section">
        <div class="title-section">
            <!-- Titolo -->
            <p class="title">{{title}}</p>
            <!-- Novità -->
            <span *ngIf="hasNew" class="highlighted" translate="homepage.items.NEW"></span>
            <!-- Link per accedere -->
            <div class="link-container">
                <p class="text" translate="homepage.items.ACCESS"></p>
                <svg-icon [src]="'assets/img/icons/chevron-right.svg'"></svg-icon>
            </div>
        </div>
        <!-- Footer -->
        <div class="footer-section">
            <!-- Parte sinistra  -->
            <p class="text">{{text}}</p>
            <!-- Parte destra -->
            <p *ngIf="expirationDate" class="date">{{ ('homepage.items.BEFORE' | translate) + expirationDate}}</p>
        </div>
    </div>
</div>