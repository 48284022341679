import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerfAlloyCommonFeedback, PerfAlloyPrivateNoteFeedback, PerfAlloySmartFeedback } from 'atfcore-commonclasses';
import moment from 'moment';

@Component({
  selector: 'card-smart',
  templateUrl: './card-smart.component.html',
  styleUrls: ['./card-smart.component.scss']
})
export class CardSmartComponent implements OnInit {
  @Input() object!: PerfAlloySmartFeedback;
  @Input() loggedUser?: any;
  @Input() feedbackStatus!: 'RECEIVED' | 'POSTED';
  @Input() isHistory: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onFeedbackSend: EventEmitter<any> = new EventEmitter();
  @Output() onShowRequest: EventEmitter<any> = new EventEmitter();

  creationDateText: string = '';
  isPosted: boolean = false;

  constructor() { }


  ngOnInit(): void {
    this.creationDateText = moment(this.object.creationDate).fromNow();
    this.isPosted = this.object.senderUserId == this.loggedUser.userId;

    if (this.object.senderUserId) {
      this.isPosted = window.location.href.indexOf(this.object.senderUserId) > -1;
    }
  }

  emitOnEdit(id: any) {
    this.onEdit.emit(id);
  }

  emitOnDelete(id: any) {
    this.onDelete.emit(id);
  }

  get competenceEvaluation() {
    return typeof this.object.competenceEvaluation != "undefined" && this.object.competenceEvaluation >= 0 ? this.object.competenceEvaluation : -1
  }
}
