import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import moment from 'moment';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'card-notify',
  templateUrl: 'card-notify.component.html',
  styleUrls: ['./card-notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardNotifyComponent implements OnInit {
  @Input() notify: any;
  @Input() isNew: boolean = false;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onGoToDetail: EventEmitter<any> = new EventEmitter();

  constructor(private store: Store<fromApp.AppState>,) {
    this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      moment.locale(applicationLang);
    })
  }

  ngOnInit() {
    this.notify.creationDateAgo = moment(this.notify.creationDate).fromNow();
  }

  emitOnDelete(data?: any) {
    this.onDelete.emit(data);
  }

  emitOnAction(data: any) {
    this.onGoToDetail.emit(data);
  }
}