<div class="page-content-wrapper d-flex flex-column justify-content-center align-items-center">
    <div class="banner-container">
        <!-- Banner -->
        <!-- <banner-home *ngIf="runningPhaseDate" [phaseName]="phaseName" [backgroundSrc]="bannerImage"
            [endDate]="runningPhaseDate" [runningYear]="runningYear.year"></banner-home> -->
        <banner-home [backgroundSrc]="bannerImage"></banner-home>
    </div>

    <ng-container
        *ngIf="(isPeopleAppraisal? true : false || isSelfAssessment? true : false) || (!isPeopleAppraisal && !isSelfAssessment) && !isLoadingPasInfo && !isLoadingPasInfoUser ">
        <div class="content-container" id="content-home">
            <!-- Contenitore Attività del team -->
            <div class="items-container" *ngIf="isPeopleAppraisal">
                <div class="backgorund-img flip"></div>

                <div class="main-title-section">
                    <div class="icon-container">
                        <svg-icon [src]="'assets/img/icons/users.svg'"></svg-icon>
                    </div>
                    <p class="title">
                        {{ "homepage.ACTIVITIES_RELATED" | translate }}<span class="border">{{ "homepage.MY_TEAM" |
                            translate }}</span>
                    </p>
                </div>

                <!-- separatore -->
                <div class="divisor"></div>

                <!-- Items -->
                <div class="items" *ngIf="pasData">
                    <!-- Autovalutazione -->
                    <item-home
                        *ngIf="canAccessPersonalDevelopment && pasData?.perfYear && subordinatesWithPhaseStatuses?.length"
                        [icon]="'assets/img/icons/crosshair.svg'" [text]="'homepage.items.EVALUATION_TEXT' | translate"
                        [expirationDate]="pasData.endDate" [hasNew]="pasData.isNewPas"
                        [title]="'homepage.items.EVALUATION' | translate" (onClicked)="goToPersonDetails()"></item-home>
                    <!-- Dashboard -->
                    <item-home *ngIf="canAccessTheDashboard" [icon]="'assets/img/icons/pie-chart.svg'"
                        [text]="'homepage.items.DASHBOARD_TEXT' | translate"
                        [title]="'homepage.items.DASHBOARD_TITLE' | translate" (onClicked)="goToDashboard()">
                    </item-home>

                    <!-- Feedback 360° -->
                    <item-home *ngIf="pasData.showFeedback360" [icon]="'assets/img/icons/orbit.svg'"
                        (onClicked)="goToPeopleAppraisal360Feedback()"
                        [text]="'homepage.items.FEEDBACK_360_TEXT' | translate"
                        [title]="'homepage.items.FEEDBACK_360' | translate"></item-home>
                </div>
            </div>

            <!-- Contenitore Attività personali -->
            <div class="items-container" *ngIf="isSelfAssessment || (!isPeopleAppraisal && !isSelfAssessment)">
                <div class="backgorund-img"></div>

                <div class="main-title-section">
                    <div class="icon-container white">
                        <svg-icon [src]="'assets/img/icons/user.svg'"></svg-icon>
                    </div>
                    <p class="title" translate="homepage.MY_ACTIVITIES">
                        <span class="border" translate="homepage.PERSONAL"></span>
                    </p>
                </div>

                <!-- separatore -->
                <div class="divisor"></div>

                <!-- Items -->
                <div class="items white" *ngIf="pasDataSelf && !isLoadingPasInfoUser">
                    <!-- Autovalutazione -->
                    <item-home *ngIf="canAccessPersonalDevelopment && pasDataSelf?.perfYear"
                        [icon]="'assets/img/icons/crosshair.svg'"
                        [text]="'homepage.items.EVALUATION_PERSONAL_TEXT' | translate"
                        [expirationDate]="pasDataSelf.endDate" [hasNew]="pasDataSelf.isNewPas"
                        [title]="'homepage.items.EVALUATION_PERSONAL' | translate"
                        (onClicked)="goToPersonDetails(true)"></item-home>

                    <!-- Smart Feedback -->
                    <item-home *ngIf="canAccessToSmartFeedback && pasDataSelf?.showFeedback"
                        [icon]="'assets/img/icons/iteration-ccw.svg'"
                        [text]="'homepage.items.SMART_FEEDBACK_TEXT' | translate"
                        [title]="'homepage.items.SMART_FEEDBACK' | translate" (onClicked)="goToSmartFeedback()">
                    </item-home>

                    <!-- Peer Feedback -->
                    <item-home *ngIf="canAccessToPeerFeedback && pasDataSelf?.showFeedback"
                        [icon]="'assets/img/icons/message-circle.svg'"
                        [text]="'homepage.items.PEER_FEEDBACK_TEXT' | translate"
                        [title]="'homepage.items.PEER_FEEDBACK' | translate" (onClicked)="goToPeerFeedback()">
                    </item-home>

                    <!-- Appunti privati , disativati perchè è stato richiesto di sostituirlo con accesso (false forzato per nascondere l'item)-->
                    <item-home *ngIf="canAccessToPrivateNotes && false" [icon]="'assets/img/icons/edit.svg'"
                        [text]="'homepage.items.PRIVATE_NOTES_TEXT_SELF' | translate"
                        [title]="'homepage.items.PRIVATE_NOTES_TITLE' | translate" (onClicked)="goToPrivateNotes()">
                    </item-home>

                    <!-- I tuoi feedback -->
                    <item-home *ngIf="canAccessToFeedback360" [icon]="'assets/img/icons/messages-square-bold.svg'"
                        [text]="'homepage.items.YOUR_FEEDBACK_DESC' | translate"
                        [title]="'homepage.items.YOUR_FEEDBACK' | translate" (onClicked)="goToYourFeedbacks()">
                    </item-home>

                    <!-- Feedback 360° Ricevere -->
                    <item-home *ngIf="canAccessToFeedback360 && pasDataSelf.showFeedback360Suggestions"
                        [icon]="'assets/img/icons/orbit.svg'" [text]="'homepage.items.FEEDBACK_360_RECEIVE' | translate"
                        (onClicked)="goTo360Feedback(true)"
                        [title]="'homepage.items.FEEDBACK_360_SUGGESTIONS' | translate">
                    </item-home>

                    <!-- Feedback 360° Richiedere -->
                    <item-home *ngIf="canAccessToFeedback360 && pasDataSelf.showFeedback360"
                        [icon]="'assets/img/icons/orbit.svg'" [hasNew]="pasDataSelf.feedback360NewBadge"
                        [text]="'homepage.items.FEEDBACK_360_REQUEST' | translate" (onClicked)="goTo360Feedback(false)"
                        [title]="'homepage.items.FEEDBACK_360_REQUESTS' | translate"></item-home>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngIf="(isPeopleAppraisal? false : false || isSelfAssessment? false : false) || isLoadingPasInfo || isLoadingPasInfoUser">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div>


<!-- Modale di scarica report per collaboratori completati del CPP -->
<modal id="completed-collaborators-cpp-modal" (onClose)="closeCollaboratorsCppCompletedModal()"
    (onConfirm)="closeCollaboratorsCppCompletedModal(true)" [isDisabled]="!selectedUserList.length"
    confirmLabel="{{ 'technicalAssessment.selfAssessment.DOWNLOAD_REPORT' | translate}}"
    [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeCollaboratorsCppCompletedModal()">
    <p class="modal-title black center">
        CPP - Report Technical Self Assessment
    </p>
    <ng-container>
        <div class="margin-top30 margin-bottom30"
            *ngIf="cppInfo && (!cppInfo.completedCollaboratorsData || !cppInfo.completedCollaboratorsData.length)">
            <p class="text-modal bold-text black" translate="technicalAssessment.selfAssessment.NO_COMPLETED_COLLABS">
            </p>
        </div>
        <div *ngIf="cppInfo && cppInfo.completedCollaboratorsData?.length" class="completed-collabs-cpp-container">
            <p class="text-modal bold-text black">
                {{ cppInfo.totalCollaboratorsCppCompleted + ('technicalAssessment.selfAssessment.COLLABORATORS_ON' |
                translate)
                + cppInfo.totalCollaborators + ('technicalAssessment.selfAssessment.COMPLETED_TECHNICAL_ASSESSMENT' |
                translate)}}</p>
            <p class="text-modal grey margin-bottom24">
                {{ 'technicalAssessment.selfAssessment.SELECT_COLLABS_FOR_REPORT' | translate}}</p>
            <input-container [isWithoutInfoIcon]="true">
                <!-- ricerca -->
                <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                    (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                    placeholder="{{'privateNotes.createUpdate.SEARCH_PLACEHOLDER' | translate}}">
                </alloy-input>
                <div class="user-card-collection-container">
                    <!-- Card utente -->
                    <div class="row-boxes d-flex flex-row flex-wrap justify-content-start align-items-center">
                        <user-card class="full-width margin-top16" *ngFor="let user of completedCollaboratorsList"
                            [user]="user" [selectedUserList]="selectedUserList" [isFeedbackCard]="true"
                            (onCheck)="selectUser($event)" [fromCppCollabs]="true"></user-card>
                    </div>
                </div>

            </input-container>
        </div>
    </ng-container>
</modal>

<!-- Upward Feedback modal manager 2 livello -->
<modal id="upward-feedback" (onClose)="closeUpwardFeedbackModal()" [isDisabled]="!selectedFeedbackManager"
    [confirmLabel]="'upwardFeedback.SEND_FEEDBACK' | translate" (onConfirm)="goToCheckFeedbackResults()"
    [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeUpwardFeedbackModal()">
    <modal-text-content [title]="'upwardFeedback.TITLE' | translate"
        [subtitle]="'upwardFeedback.modals.CHOOSE_MANGER_SUB' | translate"
        [text]="'upwardFeedback.modals.CHOOSE_MANAGER_DESCR' | translate"></modal-text-content>
    <div class="user-list" *ngIf="upwardFeedbackManagers && upwardFeedbackManagers.length">
        <!-- <user-card *ngFor="let user of upwardFeedbackManagers" [user]="user" [isChecked]="user.isChecked"
            (onCheck)="chooseUpwardFeedbackUser(user)" [radio]="true" [fromCppCollabs]="true"
            [disabled]="!user.resultAvailable"></user-card> -->
    </div>
</modal>

<modal id="completion-cpp-modal" (onClose)="closeCompletionCppModal()"
    [confirmLabel]="'technicalAssessment.selfAssessment.DOWNLOAD_REPORT' | translate"
    (onConfirm)="closeCompletionCppModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
    (onCancel)="closeCompletionCppModal()">
    <modal-text-content [title]="'technicalAssessment.selfAssessment.TECHNICAL_SELF_ASSESSMENT' | translate"
        [subtitle]="('technicalAssessment.selfAssessment.TECHNICAL_SELF_ASSESSMENT_COMPLETED_SUBTITLE' | translate) + (cppInfo?.completionDate | date: 'dd/MM/YYYY')"
        [text]="'technicalAssessment.selfAssessment.TECHNICAL_SELF_ASSESSMENT_COMPLETED_DESC' | translate">
    </modal-text-content>
</modal>