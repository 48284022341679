<div class="main-container">
    <!-- Peerfeedback -->
    <ng-container *ngIf="object">
        <div class="rectangle-container">
            <!-- Card-->
            <div class="card-container">
                <!-- Header -->
                <card-feedback-header icon="assets/img/icons/orbit.svg" [creationDate]="commentDateText"
                    [hasAvatar]="true" [avatarRight]="false" [user]="object.intermediateUser" [title]="responseTitle">
                </card-feedback-header>
                <div class="divisor-line"></div>
                <!-- Informazioni feedback -->
                <div class="information-container">
                    <!-- Richiesto da -->
                    <div class="information-text-container">
                        <p class="information-title" translate="feedbackSection.REQUESTED_BY"></p>
                        <p class="information-desc">
                            {{
                            object.senderUser ? (object.senderUser.surname + ' ' + object.senderUser.forename) : ''
                            }}
                        </p>
                    </div>
                    <!-- Motivazione -->
                    <div class="information-text-container">
                        <p class="information-title" translate="feedbackSection.peerFeedback.MOTIVATION"></p>
                        <p class="information-desc" translate="feedbackSection.360_MOTIVATION"></p>
                    </div>
                    <!-- Richiesto da -->
                    <div class="actions-detail" [ngClass]="{'disabled': feedbackStatus == 'SENT'}">
                        <svg-icon src="assets/img/icons/message-circle-bold.svg"></svg-icon>
                        <a translate="360Feedback.table.FEEDBACK_DETAILS"
                            (click)="emitOnDetailsClicked(object.feedbackId || '')">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>