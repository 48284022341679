<page-container *ngIf="langs && langs.length">

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content" *ngIf="login">
    <div class="page-login-content">
      <ng-container *ngIf="!showLoader">
        <div class="login-wrapper">
          <svg-icon src="assets/img/icons/sol-big-logo.svg" class="login-icon" [applyClass]="true"></svg-icon>
          <div class="container-title">
            <h1><b translate="login.SIGN_UP_BUTTON"></b></h1>
            <p class="login-title-text" translate="login.signUp.TITLE_PAGE"></p>
          </div>
          <!--Email-->
          <input-container [tooltipModalMessage]="usernameModalMessage" [removePadding]="true" [isValued]="false"
            [isObbligatory]="true" title="{{'login.MAIL' | translate}}">
            <alloy-input [customFormControl]="emailControl" [hasFormControl]="true" [isInvalid]="emailError()"
              [iconRight]="iconRight"
              [errorText]="!emailValidate() && signUpForm.email && signUpForm.email.trim().length? ('login.EMAIL_ERROR_INSERTED' | translate) : ''"
              autocomplete="on" (onIconClicked)="onSignUp()" typeInput="email" [bindValue]="signUpForm.email"
              (onModelChanged)="emailChanged($event)" placeholder="{{'login.MAIL_PLACEHOLDER' | translate}}"
              (keyup.enter)="!signUpButtonIsActive() || !emailValidate()? '': onSignUp()">
            </alloy-input>
          </input-container>
          <!--Password-->
          <input-container [removePadding]="true"
            [inputStatusLabel]="passwordValidate() && signUpForm.password.trim().length? ('login.signUp.VALID_PASSWORD' | translate) : ''"
            instructions="{{'login.signUp.PASSWORD_REQUIREMENTS' | translate}}" [isWithoutInfoIcon]="true"
            [tooltipModalMessage]="passwordModalMessage" [isValued]="false" [isObbligatory]="true"
            title="{{'login.PASSWORD' | translate}}">
            <alloy-input [hasFormControl]="true" [customFormControl]="passwordControl" [isInvalid]="passwordError()"
              [isValid]="passwordValidate() && signUpForm.password.trim().length? true : false"
              [iconRight]="iconRightEye" [typeInput]="typeInput" autocomplete="on" typeInput="password"
              [bindValue]="signUpForm.password" (onIconClicked)="changeTypeInput(typeInput)"
              (onModelChanged)="passwordChanged($event)" placeholder="{{'login.PASSWORD_PLACEHOLDER' | translate}}"
              (keyup.enter)="!signUpButtonIsActive() || !emailValidate()? '': onSignUp()">
            </alloy-input>
          </input-container>
          <!--Ripeti password-->
          <input-container [removePadding]="true"
            [inputStatusLabel]="isValid()? ('login.signUp.VALID_PASSWORD' | translate) : ''" [isWithoutInfoIcon]="true"
            [tooltipModalMessage]="passwordModalMessage" [isValued]="false" [isObbligatory]="true"
            title="{{'login.REPEAT_PASSWORD' | translate}}">
            <alloy-input [hasFormControl]="true" [customFormControl]="passwordControl"
              (onIconClicked)="changeTypeInput(typeInput)" [isInvalid]="repeatPasswordError()" [isValid]="isValid()"
              [iconRight]="iconRightEye" [typeInput]="typeInput" autocomplete="on" typeInput="password"
              [bindValue]="signUpForm.repeatPassword" (onModelChanged)="repeatPasswordChanged($event)"
              placeholder="{{'login.PASSWORD_PLACEHOLDER' | translate}}"
              (keyup.enter)="!signUpButtonIsActive() || !emailValidate()? '': onSignUp()"></alloy-input>
          </input-container>
          <!--Pulsante registrati-->
          <div class="button-login-container">
            <alloy-button [disabled]="!isValid() || !emailValidate()" type="primary fill" size="large"
              label="{{'login.SIGN_UP_BUTTON' | translate}}" (onButtonClicked)="onSignUp()">
            </alloy-button>
          </div>

          <div class="divider-or">
            <div class="divider"></div>
            <p>Oppure</p>
            <div class="divider"></div>
          </div>
          <div class="sign-up">
            <div class="container-title-sign-up">
              <h1><b>Accedi</b></h1>
              <p class="login-title-text" translate="login.signUp.TITLE_LOGIN_OPTION"></p>
            </div>
            <alloy-button size="large" type="secondary" label="{{'login.SIGN_IN_BUTTON' | translate}}"
              (onButtonClicked)="goToSignIn()">
            </alloy-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showLoader">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>

    <!--Modale successful signUp-->
    <modal id="modalSignUpInProgress" class="modal-signup-in-progress" [isMessageModal]="true" (onClose)="goToSignIn()"
      (onConfirm)="goToSignIn()" confirmLabel="{{'login.modals.CLOSE' | translate}}">
      <modal-text-content title="{{'login.modals.SIGN_UP_IN_PROGRESS' | translate}}"
        subtitle="{{'login.modals.SIGN_UP_IN_PROGRESS_SUBTITLE' | translate}}"
        text="{{'login.modals.SIGN_UP_IN_PROGRESS_DESCR' | translate}}">
      </modal-text-content>
    </modal>
    <!--Modale errore generico-->
    <modal id="modalGenericError" class="modal-generic-error" [isMessageModal]="true"
      (onClose)="closeGenericErrorModal()" (onConfirm)="closeGenericErrorModal()"
      confirmLabel="{{'login.modals.TRY_AGAIN' | translate}}">
      <modal-text-content title="{{'login.modals.WARNING' | translate}}"
        subtitle="{{'login.modals.TAKE_NEW_ATTEMPT' | translate}}" text="{{'errors.USER_NOT_FOUND' | translate}}">
      </modal-text-content>
    </modal>
  </div>
</page-container>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>