import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alloy-button',
  templateUrl: 'buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonsComponent implements OnInit {
  @Input() label: string = "";
  // componibile, primary o secondary + bordless o link
  @Input() type: string = "primary"
  // dimensione default a large 56px, medium 40px
  @Input() size: string = "large";
  @Input() iconLeft: string = '' //"assets/img/icons/arrow-left.svg";
  @Input() iconRight: string = '' // "assets/img/icons/arrow-right.svg";
  @Input() disabled: boolean = false;
  @Input() iconLeftClass: string = '';
  @Input() signUp: string = '';
  @Input() mobileShrink: boolean = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onButtonClicked: EventEmitter<Date> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  emitOnClick(event: any) {
    if (!this.disabled) {
      this.onButtonClicked.emit(event);
    }
  }

  checkIconLeft() {
    if (this.type.indexOf('link') == -1 && (this.iconRight || this.iconLeft)) {
      return true;
    } else if (this.type.indexOf('link') != -1 && this.iconLeft) {
      return true;
    }
    return false;
  }

  checkIconRight() {
    if (this.type.indexOf('link') == -1 && (this.iconRight || this.iconLeft)) {
      return true;
    } else if (this.type.indexOf('link') != -1 && this.iconRight) {
      return true;
    }
    return false;
  }
}
