import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter, tap, withLatestFrom, take, finalize } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { RequiredAuth } from 'src/commonclasses';
import { combineLatest, forkJoin, of, zip } from 'rxjs';

const USERS_AUTH = [
  RequiredAuth.PERFORMANCECARE_USER_COMMON,
  RequiredAuth.PERFORMANCECARE_USER_STAFF,
  RequiredAuth.PERFORMANCECARE_USER_STAFF_STI,
  RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_PROFESSIONAL,
  RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_RETAIL,
  RequiredAuth.PERFORMANCECARE_USER_OPERATIONS
]

@Injectable()
export class SelfAssessmentGuard  {
  constructor(private store: Store<fromApp.AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.isAuthenticated)
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        switchMap(() => {
          const isSelfAssessment$ = this.store.select(fromApp.getIsSelfAssessment);
          const isPeopleAppraisal$ = this.store.select(fromApp.getIsPeopleAppraisal);
          return combineLatest([isSelfAssessment$, isPeopleAppraisal$]);
        }),
        map((isAuthenticated) => {
          const isAllowed: boolean = isAuthenticated[0] || isAuthenticated[1];
          const geneicUser = !isAuthenticated[0] && !isAuthenticated[1];
          return geneicUser ? geneicUser : isAllowed;
        }),
        onceIfNotEmpty(),
        map((isAllowed) => {
          if (isAllowed) {
            return true;
          } else {
            this.router.navigate(["/403"]);
            return false;
          }
        })
      );
  }
}
