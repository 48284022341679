<div class="box-container" (click)="emitOnButtonClicked()"
  [ngClass]="{'disabled': !isPeopleAppraisalBox && !midTermData.isPhaseActive}">
  <div class="img-content" style='background-image:url({{imgPath}})'></div>
  <div class="info-container" ngClass.sm="mobile" ngClass.xs="mobile" ngClass.xxs="mobile">
    <div class="info">
      <!-- Lato sinistro manager -->
      <ng-container *ngIf="isPeopleAppraisalBox">
        <p class="title-text">{{title}}</p>
        <!-- Da completare -->
        <ng-container *ngIf="midTermData.status == 'to_complete'">
          <p class="description">{{'midTerm.home.MANAGER' | translate}}</p>
          <item-list-team-status class="margin-top10" styleItem="border" type="toStart"
            message="generic.TO_BE_COMPLETED">
          </item-list-team-status>
        </ng-container>
        <!-- Completato -->
        <ng-container *ngIf="midTermData.status == 'completed'">
          <ng-container *ngIf="midTermData.home">
            <p class="description">{{'midTerm.home.MANAGER' | translate}}</p>
            <item-list-team-status class="margin-top10" styleItem="border" type="completed" message="generic.COMPLETED">
            </item-list-team-status>
          </ng-container>
          <ng-container *ngIf="!midTermData.home">
            <p class="description">{{ midTermData.completedDescr }}</p>
            <div class="completed-status-wrapper" *ngIf="midTermData.revisionData">
              <p translate="midTerm.statuses.{{ midTermData.revisionData.status }}"></p>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!midTermData.isPhaseActive">
          <div class="status">
            <p class="date-label" [innerHTML]="midTermData.dateDescription"></p>
          </div>
          <p></p>
        </ng-container>

      </ng-container>
      <!-- Lato sinistro self assessment -->
      <ng-container *ngIf="!isPeopleAppraisalBox">
        <p class="title-text">{{title}}</p>
        <ng-container *ngIf="!midTermData.isPhaseActive">
          <div class="status">
            <p class="date-label" [innerHTML]="midTermData.dateDescription"></p>
          </div>
          <p></p>
        </ng-container>
        <ng-container *ngIf="midTermData.isPhaseActive">
          <ng-container
            *ngIf="!midTermData.isDateValid && midTermData.midTermRevisionPeopleAppraisalStatus == 'NOT_REVISABLE'">
            <p class="description">{{'midTerm.statuses.NOT_REVISABLE' | translate}}</p>
          </ng-container>
          <ng-container *ngIf="midTermData.isDateValid">
            <!-- && status da completare -->
            <ng-container *ngIf="midTermData.status == 'to_complete'">
              <p class="description">{{'midTerm.home.COMPLETE_MID' | translate}}</p>
              <item-list-team-status class="margin-top10" styleItem="border" type="toStart"
                message="generic.TO_BE_COMPLETED">
              </item-list-team-status>
            </ng-container>
            <ng-container *ngIf="midTermData.status == 'completed'">
              <!-- && status completato -->
              <p class="description">{{ midTermData.completedDescr }}</p>
              <div class="completed-status-wrapper">
                <!--svg-icon class="status-icon" [src]="MID_STATUS_SRC[midTermData.revisionDataStatus]"
                  [applyClass]="true">
                </svg-icon-->
                <p translate="midTerm.statuses.{{ midTermData.revisionDataStatus }}"></p>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="counter-wrapper show-gt-sm">
    <ng-container *ngIf="isPeopleAppraisalBox">
      <!-- Feedback -->
      <div class="counter-cell">
        <p class="value">{{ midTermData.completedMidTermReviewCount }}</p>
        <p class="description" translate="midTerm.home.COMPLETED"></p>
      </div>
      <!-- Evidenze -->
      <div class="counter-cell">
        <p class="value">{{ midTermData.totalMidTermReviewCount }}</p>
        <p class="description" translate="midTerm.home.TOTAL"></p>
      </div>
      <!-- Osservazione -->
      <div class="counter-cell" [ngClass]="{'red-background': midTermData.toCompleteMidTermReviewCount > 0} ">
        <p class="value">{{ midTermData.toCompleteMidTermReviewCount }}</p>
        <p class="description" translate="midTerm.home.TO_COMPLETE"></p>
      </div>
    </ng-container>
    <!-- lato destro self assessment-->
    <ng-container *ngIf="!isPeopleAppraisalBox">
      <!-- Obiettivi-->
      <div class="counter-cell">
        <p class="value">{{ midTermData.goalCount }}</p>
        <p class="description" translate="midTerm.home.OBJECTIVE"></p>
      </div>
      <!-- Feedback -->
      <div class="counter-cell">
        <p class="value">{{ midTermData.feedbackNumber }}</p>
        <p class="description" translate="midTerm.home.FEEDBACK"></p>
      </div>
      <!-- Evidenze -->
      <div class="counter-cell">
        <p class="value">{{ midTermData.evidenceNumber }}</p>
        <p class="description" translate="midTerm.home.EVIDENCES"></p>
      </div>
      <ng-container *ngIf="midTermData.newObservationToWatch">
        <!-- Osservazione -->
        <div class="counter-cell" [ngClass]="{'red-background': midTermData.observationCount > 0} ">
          <p class="value">{{ midTermData.observationCount }}</p>
          <p class="description" translate="midTerm.home.OBSERVATION"></p>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>