<ng-container [ngSwitch]="level">

  <!-- Non applicabile -->
  <div *ngSwitchCase="-1" class="rating-item-na" [ngClass]="{
        'active': active,
        'basic': !active,
        'read-only': readOnly
      }" (click)="getLevel(-1)">
    N/A
  </div>

  <!-- Tutti gli altri -->
  <div *ngSwitchDefault class="rating-item"
    [ngClass]="{ 'active': active, 'basic': !active, 'read-only': readOnly, 'sm': size == 'small', 'lg': size == 'big', 'md': size != 'big' && size != 'small' }"
    style="--level: '{{level}}'" (click)="getLevel(level)">
  </div>
</ng-container>