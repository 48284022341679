<div class="competence-evaluation-container" [ngClass]="{'no-container': noContainer}" id="{{ id + '-container' }}">
  <!-- Titolo categoria -->
  <div class="main-title-container">
    <ng-container *ngIf="competenceList.category">
      <p class="competence-category">{{ competenceList?.category || '' }}</p>
    </ng-container>
    <!-- Label per valutazione self assessment e people appraisal -->
    <ng-container *ngIf="isPostCalibration">
      <!-- Label per people appraisal -->
      <div class="title-section-container">
        <div class="divisor-vertical"></div>
        <div class="title-section left">
          <p class="title-left"> {{'competenceComponent.YOUR_EVALUATION' | translate}}</p>
        </div>
        <div class="divisor-vertical"></div>
        <div class="title-section right">
          <p class="title-left"> {{isManager ? ('competenceComponent.PEOPLE_EVALUATION' | translate) :
            ('competenceComponent.SELF_EVALUATION' | translate)}} </p>
        </div>
      </div>
    </ng-container>

    <!--div *ngIf="!hideSkillMatrix" class="skill-matrix-container" (click)="onSkillMatrixDownload()">
      <p translate="evaluations.SKILL_MATRIX" role="button"></p>
      <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true">
      </svg-icon>
    </div-->
  </div>
  <!-- Lista competenze per categoria-->
  <ng-container *ngFor="let tag of competenceList.competences; let last = last; let competenceIndex = index;">
    <!-- Divisore orizzontale se ho più competenze -->
    <div class="divisor-horizontal"></div>

    <div *ngIf="!tag.isPartialAverage" class="competence-data-container" [ngClass]="{'reduce-gap': isPostCalibration}"
      id="{{ 'competenceIndex' + competenceIndex + '-container' }}">
      <!-- Lato sinistro con titolo e descrizione -->
      <div class="data-content">
        <ng-container *ngIf="tag.title">
          <div class="title-container">
            <p class="title">{{ tag.title }}</p>

            <svg-icon *ngIf="!isLeadershipRating" [applyClass]="true" class="icon" src="assets/img/icons/info.svg"
              (click)="onInfoClicked(tag, competenceList.category)">
            </svg-icon>
          </div>
        </ng-container>

        <p class="description" [innerHTML]="tag.text"></p>

        <div class="counters" *ngIf="!hideComments || !hideFeedbacks">
          <!-- Commenti -->
          <span *ngIf="!hideComments" [ngClass]="{ 'active': tag.comments?.length > 0 }"
            (click)="onCommentList(tag.comments?.length > 0, tag)">
            {{ (tag.comments?.length ?? 0) + " " + ('evaluations.COMMENTS' | translate) }}
          </span>

          <!-- Feedback -->
          <span *ngIf="!hideFeedbacks" [ngClass]="{ 'active': tag.feedbacks?.length > 0 }"
            (click)="onFeedbackList(tag)">
            {{(tag.feedbacks?.length ?? 0) + " " + ('evaluations.FEEDBACK' | translate )}}
          </span>
        </div>
      </div>
      <div class="divisor-vertical"></div>
      <!-- Rating competenza -->
      <!-- Visualizzazione forzata -->
      <div class="evaluation-rating-stars" *ngIf="!isPostCalibration || !tag.colleagueEvaluationRating">
        <!-- Senza recap -->
        <competence-evaluation-system [isNotApplicable]="isNotApplicable" [direction]="'column'" [size]="'big'"
          [level]="tag.evaluationRating" [canValueNA]="canValueNA" [items]="5"
          (onButtonClicked)="onRating({ rating: $event, tag})" [readOnly]="disabled" [competenceScale]="true"
          [showTitles]="true" [showDescr]="false" [titleStyleAsDescription]="true"
          [valueFirstStar]="tag?.evaluationScale[0]?.text??''" [valueSecondStar]="tag?.evaluationScale[1]?.text??''"
          [valueThirdStar]="tag?.evaluationScale[2]?.text??''" [valueFourthStar]="tag?.evaluationScale[3]?.text??''"
          [valueFifthStar]="tag?.evaluationScale[4]?.text??''">
        </competence-evaluation-system>
        <p *ngIf="!hideFeedbacks" class="no-wrap" [ngClass]="{'disabled-insert-comment': disabled}"
          (click)="insertCommentEvent(competenceIndex)" role="button">{{'evaluations.INSERT_COMMENT' | translate}}</p>
      </div>

      <!-- Rating competenza (con recap)-->
      <div class="evaluation-rating-stars horizontal" *ngIf="isPostCalibration && tag.colleagueEvaluationRating">
        <competence-evaluation-system [isNotApplicable]="isNotApplicable" [direction]="'column'" [size]="'small'"
          [level]="tag.evaluationRating" [canValueNA]="canValueNA" [items]="5" [readOnly]="!canEditInCompare"
          (onButtonClicked)="onRating({ rating: $event, tag})" [competenceScale]="true" [showTitles]="true"
          [showDescr]="false" [titleStyleAsDescription]="true" [valueFirstStar]="tag?.evaluationScale[0]?.text??''"
          [valueSecondStar]="tag?.evaluationScale[1]?.text??''" [valueThirdStar]="tag?.evaluationScale[2]?.text??''"
          [valueFourthStar]="tag?.evaluationScale[3]?.text??''" [valueFifthStar]="tag?.evaluationScale[4]?.text??''">
        </competence-evaluation-system>
        <div class="divisor-vertical height100"></div>
        <competence-evaluation-system [isNotApplicable]="isNotApplicable" [direction]="'column'" [size]="'small'"
          [level]="tag.colleagueEvaluationRating" [canValueNA]="canValueNA" [items]="5" [readOnly]="true"
          [competenceScale]="true" [showTitles]="true" [showDescr]="false" [titleStyleAsDescription]="true"
          [valueFirstStar]="tag?.evaluationScale[0]?.text??''" [valueSecondStar]="tag?.evaluationScale[1]?.text??''"
          [valueThirdStar]="tag?.evaluationScale[2]?.text??''" [valueFourthStar]="tag?.evaluationScale[3]?.text??''"
          [valueFifthStar]="tag?.evaluationScale[4]?.text??''" [secondaryColor]="true">
        </competence-evaluation-system>
      </div>
    </div>

    <!-- Media parziale -->
    <ng-container *ngIf="competenceList?.partiaCompetenceslAvgRound !== undefined && last">
      <div class="divisor-horizontal"></div>

      <div class="competence-data-container" id="{{ 'competenceIndex' + competenceIndex + '-container' }}">
        <!-- Lato sinistro con titolo e descrizione -->
        <div class="data-content">
          <!-- <ng-container *ngIf="tag.title">
            <div class="title-container">
              <p class="title">{{ tag.title }}</p>
              <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/info.svg"
                (click)="onInfoClicked(tag, competenceList.category)">
              </svg-icon>
            </div>
          </ng-container> -->
          <div class="title-container">
            <p class="avg-title">Media Parziale</p>
            <!-- <svg-icon *ngIf="showInfo" src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"></svg-icon> -->
          </div>
          <!-- <p class="description">{{tag.text}}</p> -->
          <p class="avg-subtitle">{{ competenceList?.category || '' }}</p>
        </div>
        <div class="divisor-vertical"></div>
        <!-- Rating competenza -->
        <!-- <ng-container *ngIf="!tag.selfRatingAvgNum">
          <star-rating id="{{ (id || ('competenceIndex' + competenceIndex)) + '-stars' }}" [scale]="ratingScale"
            [disabled]="disabled || tag.disabled" [showFullNumber]="true" [fullNumber]="tag.ratingAvgString"
            [forceRating]="tag.ratingStars">
          </star-rating>
        </ng-container> -->
        <div class="evaluation-rating-stars">
          <ng-container *ngIf="!tag.selfRating">
            <competence-evaluation-system [isNotApplicable]="false" [direction]="'column'" [size]="'big'"
              [readOnly]="true" [items]="5" [level]="competenceList?.partiaCompetenceslAvgRound"
              [competenceScale]="true" [showTitles]="true" [showDescr]="false" [titleStyleAsDescription]="true">
            </competence-evaluation-system>
          </ng-container>
          <p class="number">{{ competenceList?.partiaCompetenceslAvg | number:'1.2-2' }}</p>
        </div>

        <!-- Rating competenza (con recap)-->
        <ng-container *ngIf="tag.selfRatingAvgNum">
          <div class="rating-star-content">
            <star-rating customContainerClass="small-star-size" [grayColor]="true" [scale]="ratingScale"
              [disabled]="true || tag.disabled" [showFullNumber]="true" [fullNumber]="tag.selfRatingAvgString"
              [forceRating]="tag.selfRatingStars">
            </star-rating>

            <div class="divisor-vertical"></div>
            <star-rating customContainerClass="small-star-size" [scale]="ratingScale"
              [disabled]="disabled || tag.disabled" [showFullNumber]="true" [fullNumber]="tag.ratingAvgString"
              [forceRating]="tag.ratingStars"></star-rating>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- Divisore orizzontale se ho più competenze -->
    <!-- <ng-container *ngIf="!last">
      <div class="divisor-horizontal"></div>
    </ng-container> -->

  </ng-container>

  <!-- footer categoria -->
  <div class="main-title-container bottom">
    <ng-container
      *ngIf="titlesRightSection && competenceList.competences[0].selfRating && competenceList.competences?.length > 2">
      <div class="title-section-container">
        <div class="divisor-vertical"></div>
        <div class="title-section left">
          <p class="title-left"> {{titlesRightSection.left}} </p>
        </div>
        <div class="divisor-vertical"></div>
        <div class="title-section right">
          <p class="title-left"> {{titlesRightSection.right}} </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>