<div class="picker-container" [ngClass]="{disabled: disabled}">

  <input #datePickerInput class="datetimepicker-input" attr.aria-label="{{ placeholder | translate }}"
    [owlDateTime]="dt2" [disabled]="disabled" placeholder="{{ placeholder | translate }}" [ngModel]="value"
    [min]="minDate || newDate" (ngModelChange)="updateModelFromInputvalue(datePickerInput);"
    (dateTimeChange)="updateModel(this, $event);" [max]="maxDate">

  <button type="button" [owlDateTimeTrigger]="dt2" [disabled]="disabled">
    <svg-icon src="../../../assets/img/icons/calendar.svg"></svg-icon>
  </button>

  <owl-date-time class="date-picker" #dt2 [firstDayOfWeek]="options.firstDayOfWeek" [disabled]="disabled"
    [pickerMode]="pickerMode" [pickerType]="pickerType" (afterPickerClosed)="cleanOpenedStyle()"></owl-date-time>
</div>