import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'goal-box',
  templateUrl: './goal-box.component.html',
  styleUrls: ['./goal-box.component.scss']
})
export class GoalBoxComponent {
  @Input() insertBy: string = '';
  @Input() desc: string = '';
  @Input() isNew: boolean = false;
  @Input() hideMenu: boolean = false;
  @Input() menuOptions: Array<{
    id: string,
    title: string,
    icon: string,
    isChecked: boolean
  }> = [];

  @Output() onClicked: EventEmitter<string> = new EventEmitter();

  isOpen: boolean = false;

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  onMenuOptionClicked(optionId: any) {
    this.isOpen = false;
    this.onClicked.emit(optionId);
  }
}
