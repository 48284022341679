<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goToUpdatePrivateNote(true)" [isBackButton]="true"
      [title]="('privateNotes.selfAssessment.SIDENAV_TITLE' | translate)"
      [description]="('privateNotes.selfAssessment.SIDENAV_DESCRIPTION' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingPrivateNotes">
        <div class="form-container">

          <!-- Cosa vuoi appuntare -->
          <input-container [isObbligatory]="true" [isLoading]="isFetchingPrivateNotes"
            [tooltipModalMessage]="whatToNoteModal" [isValued]="note.comment && note.comment.trim().length"
            [isObbligatory]="true" title="{{'privateNotes.createUpdate.WHAT_TO_NOTE' | translate}}">
            <alloy-text-area [bindValue]="note.comment" (onModelChanged)="whatChanged($event)"
              placeholder="{{'privateNotes.createUpdate.WHAT_TO_NOTE_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>

          <!-- Perchè stai prendendo questo appunto -->
          <input-container [isLoading]="isFetchingPrivateNotes" [tooltipModalMessage]="whyNoteModal"
            [isValued]="note.motivation && note.motivation.trim().length" [isObbligatory]="false"
            title="{{'privateNotes.createUpdate.WHY_NOTE' | translate}}">
            <alloy-text-area [bindValue]="note.motivation" (onModelChanged)="whyChanged($event)"
              placeholder="{{'privateNotes.createUpdate.WHY_NOTE_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>

          <!-- Azioni -->
          <div class="button-container">
            <alloy-button (onButtonClicked)="goToUpdatePrivateNote()"
              [disabled]="isFetchingPrivateNotes || !isObbligatoryDataInserted()" type="primary fill" size="large"
              [label]="(noteId && noteId.length) ? ('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE' | translate) : ('privateNotes.createUpdate.INSERT_PRIVATE_NOTE' | translate)">
            </alloy-button>
          </div>

        </div>
      </ng-container>
      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isFetchingPrivateNotes">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un appunto -->
<modal id="modalCreateUpdatePrivateNote" (onCancel)="closeModalCreateUpdatePrivateNote()"
  (onClose)="closeModalCreateUpdatePrivateNote(true)"
  (onConfirm)="(noteId && noteId.length) ? updatePrivateNote() : createPrivateNote()"
  cancelLabel="{{modalCreateUpdatePrivateNoteTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdatePrivateNoteTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdatePrivateNoteId" [title]="modalCreateUpdatePrivateNoteTitle"
    [subtitle]="modalCreateUpdatePrivateNoteSubtitle" [text]="modalCreateUpdatePrivateNoteText">
  </modal-text-content>
</modal>