<page-container>

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goToPeerFeedback(true)" [isBackButton]="true"
      [title]="isResponse?
      (('peerFeedback.createUpdate.SIDENAV_COLL_TITLE_RESPONSE' | translate) + senderUser?.surname + ' ' + senderUser?.name):
      isManagerResponse? ('peerFeedback.createUpdate.SIDENAV_COLL_TITLE_MANAGER_RESPONSE' | translate) + recipientUser?.surname + ' ' + recipientUser?.name :'peerFeedback.createUpdate.SIDENAV_COLL_TITLE' | translate"
      [description]="isResponse? ('peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION_RESPONSE' | translate):
      isManagerResponse? ('peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION_MANAGER_RESPONSE' | translate : {'surnameManager': senderUser?.surname, 'forenameManager': senderUser?.name,'surnameTowards': recipientUser?.surname, 'forenameTowards': recipientUser?.name}) :
       ('peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION' | translate)" [textLight]="isManagerResponse">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoadingFeedback">
        <div class="form-container">
          <!-- Se sta facendo una richiesta di feedback isResponse-->
          <ng-container *ngIf="!isResponse">
            <!-- Creazione -->
            <ng-container *ngIf="!feedbackId">
              <!-- user card -->
              <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                [isValued]="selectedUserList && selectedUserList.length"
                [title]="'peerFeedback.createUpdate.WHO' | translate">
                <!-- ricerca -->
                <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                  (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="getUsers()"
                  placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

                <div class="user-card-collection-container">
                  <!-- Card utente -->
                  <div *ngIf="!isFetchingUsers"
                    class="row-boxes d-flex flex-row flex-wrap justify-content-start align-items-center">
                    <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'feedbackUser',
                                  itemsPerPage: userCardData.numRecords,
                                  currentPage: userCardData.page,
                                  totalItems: userCardData.counter }" [user]="user"
                      [selectedUserList]="selectedUserList" [isFeedbackCard]="true" (onCheck)="selectUser($event)">
                    </user-card>
                  </div>

                  <div *ngIf="isFetchingUsers"
                    class="full-width min-height500 d-flex flex-row flex-wrap justify-content-start align-items-center spinner-flex">
                    <div class="loader spinner-medium"></div>
                  </div>
                  <div class="full-width d-flex flex-row justify-content-center align-items-center"
                    *ngIf=" !isFetchingUsers && userCardData && userCardData.list && userCardData.list.length">
                    <table-pagination id="feedbackUser" (pageChanged)="pageChanged($event)">
                    </table-pagination>
                  </div>
                </div>

              </input-container>
              <ng-container>
                <!-- Obiettivo su cui è richiesto il feedback -->
                <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback || isLoadingGoalsToAssociate"
                  *ngIf="!competenceListForPeerFeedback.length" [tooltipModalMessage]="objectiveTitleModal"
                  [isValued]="true" title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                  <alloy-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
                    [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                    [selectedItem]="selectedObjective" [loadingData]="isLoadingGoalsToAssociate" [searchable]="true"
                    bindLabel="title" placeholder="{{'peerFeedback.createUpdate.SELECT_OBJECTIVE' | translate}}">
                  </alloy-select>
                </input-container>
                <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback || isLoadingGoalsToAssociate"
                  *ngIf="competenceListForPeerFeedback.length" [tooltipModalMessage]="objectiveTitleModal"
                  [isValued]="true" title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                  <alloy-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
                    [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                    [selectedItem]="FREE_FEEDBACK" [items]="competenceListForPeerFeedback"
                    [loadingData]="isLoadingGoalsToAssociate" [searchable]="true" bindLabel="title"
                    placeholder="{{'peerFeedback.createUpdate.SELECT_OBJECTIVE' | translate}}">
                  </alloy-select>
                </input-container>
              </ng-container>
              <!-- Messaggio di richiesta -->
              <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
                [isValued]="requestMessage.length" [isObbligatory]="true"
                title="{{'peerFeedback.createUpdate.INSERT_MESSAGE' | translate}}">
                <alloy-text-area [bindValue]="requestMessage" (onModelChanged)="requestMessageChanged($event)"
                  placeholder="{{'peerFeedback.createUpdate.INSERT_MESSAGE_PLACEHOLDER' | translate}}">
                </alloy-text-area>
              </input-container>
              <!-- Pulsante richiedi peerfeedback -->
              <div class="button-container">
                <alloy-button (onButtonClicked)="goToPeerFeedback()"
                  [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary fill" size="large"
                  [label]="'peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST' | translate">
                </alloy-button>
              </div>
            </ng-container>
          </ng-container>



          <!-- Se sta rispondendo a richiesta di feedback -->
          <ng-container *ngIf="isResponse || isManagerResponse">
            <!-- Obiettivo su cui è richiesto il feedback -->
            <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback" [isValued]="true" [readonly]="true"
              title="{{'peerFeedback.createUpdate.REQUEST_FROM' | translate}}" [isWithoutInfoIcon]="true">
              <div class="custom-container">
                <div class="more-info-container">

                  <div class="user-container">
                    <avatar-img [initials]="senderUser?.initials" [src]="senderUser?.avatar" size="small"></avatar-img>
                    <p *ngIf="senderUser?.name && senderUser?.surname">{{ senderUser?.surname + ' ' + senderUser?.name |
                      truncate: [22, '...']}}
                    </p>
                  </div>
                </div>
              </div>
            </input-container>
            <!-- Obiettivo su cui è richiesto il feedback -->
            <ng-container *ngIf="isManagerResponse">
              <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback" [isValued]="true"
                [readonly]="true" title="{{'peerFeedback.createUpdate.TOWARDS' | translate}}"
                [isWithoutInfoIcon]="true">
                <div class="custom-container">
                  <div class="more-info-container">

                    <div class="user-container">
                      <avatar-img [initials]="recipientUser?.initials" [src]="recipientUser?.avatar" size="small">
                      </avatar-img>
                      <p *ngIf="recipientUser?.name && recipientUser?.surname">{{ recipientUser?.surname + ' ' +
                        recipientUser?.name |
                        truncate: [22, '...']}}
                      </p>
                    </div>
                  </div>
                </div>
              </input-container>
            </ng-container>
            <!-- Messaggio di richiesta -->
            <input-container [isLoading]="isLoadingFeedback" [isValued]="true" [readonly]="true" [isObbligatory]="false"
              title="{{'peerFeedback.createUpdate.REFERRED_TO' | translate}}" [isWithoutInfoIcon]="true">
              <alloy-input
                [bindValue]="feedbackObject?.competence?.title  ? feedbackObject?.competence?.title : 'peerFeedback.modals.NO_GOALS' | translate"
                [readonly]="true"></alloy-input>
            </input-container>
            <!-- Motivazione della richiesta -->
            <input-container [isLoading]="isLoadingFeedback" [isValued]="true" [readonly]="true" [isObbligatory]="false"
              title="{{'peerFeedback.createUpdate.MOTIVATION_FOR_REQUEST' | translate}}" [isWithoutInfoIcon]="true">
              <alloy-input [bindValue]="feedbackObject?.motivation" [readonly]="true"></alloy-input>
            </input-container>

            <input-container [isObbligatory]="true" [isLoading]="false" [isValued]="false" [isWithoutInfoIcon]="true"
              *ngIf="!feedbackObject?.competence?.isFreeFeedback"
              title="{{'feedbackSection.sendClapPage.EVALUATES_COMPETENCE' | translate}}">
              <competence-evaluation-system direction="row" size="big" aspect="manager" kind="default"
                [competenceScale]="true" [items]="5" [disabledFirstStar]="true"
                (onButtonClicked)="onPerformEvaluation($event)" [canValueNA]="true">
              </competence-evaluation-system>
            </input-container>
            <!-- Quali sono le tue osservazioni? -->
            <input-container [isLoading]="isLoadingFeedback" [readonly]="false" [isObbligatory]="true"
              [isValued]="feedbackObject?.comment && feedbackObject?.comment?.trim()?.length"
              [tooltipModalMessage]="isManagerResponse? requestMessageResponseManagerModal: requestMessageResponseModal"
              title="{{'peerFeedback.createUpdate.OBSERVATIONS' | translate}}">
              <alloy-text-area [bindValue]="feedbackObject?.comment" (onModelChanged)="observationsChanged($event)"
                placeholder="{{'peerFeedback.createUpdate.WRITE_FEEDBACK_PLACEHOLDER' | translate}}">
              </alloy-text-area>
            </input-container>
            <!-- Pulsante richiedi peerfeedback -->
            <div class="button-container">
              <alloy-button [disabled]="isLoadingFeedback || !isObbligatoryDataInsertedV2()" type="primary fill"
                size="large" (onButtonClicked)="goToPeerFeedback()"
                [label]="'peerFeedback.createUpdate.SEND_FEEDBACK' | translate">
              </alloy-button>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isLoadingFeedback">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione di un peerfeedback -->
<modal id="createFeedback" confirmLabel="{{ 'peerFeedback.modals.SEND_REQUEST' | translate }}"
  [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeModalCreatePeerFeedback()"
  (onClose)="closeModalCreatePeerFeedback()" (onConfirm)="confirmModalCreatePeerFeeback()">
  <modal-text-content title="{{ 'peerFeedback.modals.INSERT_FEEDBACK_REQUEST' | translate }}">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <div class="custom-container">
      <!-- Sottotitolo -->
      <p class="text-modal-custom mb-24">{{ 'peerFeedback.modals.REQUEST_FEEDBACK_SUBTITLE' | translate}}</p>

      <div class="more-info">
        <!-- A chi? -->
        <p class="title-modal-custom">{{ 'peerFeedback.modals.TO_WHOM' | translate}}</p>
        <div class="user-container mb-24">
          <ng-container *ngFor="let user of this.selectedUserList">
            <avatar-img [initials]="user.initials" [src]="user.avatar" size="small"></avatar-img>
            <p *ngIf="user?.name && user?.surname" class="text-modal-custom">
              {{ user?.surname + ' ' + user?.name | truncate: [22, '...'] }}
            </p>
          </ng-container>
        </div>

        <!-- Riferito a -->
        <p class="title-modal-custom">{{ 'peerFeedback.modals.REFERS_TO' | translate }}</p>
        <p class="text-modal-custom mb-24">{{ selectedObjective?.title || 'peerFeedback.modals.NO_GOALS' | translate }}
        </p>

        <!-- Motivazioni richiesta -->
        <p class="title-modal-custom">{{ 'peerFeedback.createUpdate.MOTIVATION_FOR_REQUEST' | translate }}</p>
        <p class="text-modal-custom mb-24">{{ requestMessage }}</p>
      </div>
    </div>
  </modal-text-content>
  <!-- Modale per la creazione di un peerfeedback -->
</modal>


<!-- Modale per la risposta ad un peerfeedback -->
<modal id="answerFeedback" confirmLabel="{{ 'peerFeedback.modals.SEND_RESPONSE' | translate }}"
  [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeModalCreatePeerFeedback()"
  (onClose)="closeModalCreatePeerFeedback()" (onConfirm)="confirmModalAnswerPeerFeeback()">
  <modal-text-content title="{{ 'peerFeedback.createUpdate.SEND_PEER_FEEDBACK' | translate }}">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <div class="custom-container">

      <div class="more-info">
        <!-- A chi? -->
        <p class="title-modal-custom">{{ 'peerFeedback.modals.TO_WHOM' | translate}}</p>
        <div class="user-container mb-24">
          <avatar-img [initials]="senderUser?.initials" [src]="senderUser?.avatar" size="small"></avatar-img>
          <p *ngIf="senderUser?.name && senderUser?.surname">
            {{ senderUser?.surname + ' ' + senderUser?.name | truncate: [22, '...'] }}
          </p>
        </div>

        <!-- Riferito a -->
        <p class="title-modal-custom">{{ 'peerFeedback.modals.REFERS_TO' | translate }}</p>
        <p class="text-modal-custom mb-24">{{ feedbackObject?.competence?.title }}</p>

        <!-- Motivazioni richiesta -->
        <p class="title-modal-custom">{{ 'peerFeedback.createUpdate.MOTIVATION_FOR_REQUEST' | translate }}</p>
        <p class="text-modal-custom mb-24">{{ feedbackObject?.motivation }}</p>

        <!-- Valutazione -->
        <div class="mb-24">
          <input-container [isObbligatory]="true" [isLoading]="false" [isValued]="false" [isWithoutInfoIcon]="true"
            [readonly]="true" *ngIf="!feedbackObject?.competence?.isFreeFeedback"
            title="{{'feedbackSection.sendClapPage.EVALUATES_COMPETENCE' | translate}}">
            <competence-evaluation-system direction="row" size="big" aspect="manager" kind="default"
              [competenceScale]="true" [items]="5" [canValueNA]="true" [readOnly]="true"
              [level]="peerFeedbackEvaluation" (onButtonClicked)="onPerformEvaluation($event)">
            </competence-evaluation-system>
            <!--evaluation-system direction="row" size="big" aspect="manager" kind="default" [competenceScale]="true"
              [items]="5" [readOnly]="true" [level]="peerFeedbackEvaluation" [disabledFirstStar]="true"
              (onButtonClicked)="onPerformEvaluation($event)">
            </evaluation-system-->
          </input-container>
        </div>

        <!-- Osservazioni -->
        <p class="title-modal-custom">{{ 'peerFeedback.modals.OBSERVATIONS' | translate }}</p>
        <p class="text-modal-custom mb-24">{{ feedbackObject?.comment }}</p>
      </div>
    </div>
  </modal-text-content>
</modal>