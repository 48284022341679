<div class="input-group">
  <ng-container *ngIf="!customLabel; else CustomLabel">
    <label
      *ngIf="searchIcon"
      class="label__primary"
      [ngClass]="{ 'boxed': boxedIcon, 'boxed-no-fill': !boxedIcon, 'start': placement == 'start', 'end': placement == 'end', 'rounded': design == 'rounded', 'normal': design == 'normal' }"
      (click)="onClick.emit(value)">
      <img *ngIf="boxedIcon" src="./assets/img/icons/search-white.svg">
      <img *ngIf="!boxedIcon" src="./assets/img/icons/search.svg">
    </label>
  </ng-container>
  
  <ng-template #CustomLabel>
    <label
      *ngIf="customLabel"
      class="label__primary label__primary-custom"
      [ngClass]="{ 'boxed': boxedIcon, 'boxed-no-fill': !boxedIcon, 'start': placement == 'start', 'end': placement == 'end', 'rounded': design == 'rounded', 'normal': design == 'normal' }"
      (click)="onClick.emit(value)">
      <img *ngIf="boxedIcon" [src]="src">
      <img *ngIf="!boxedIcon" [src]="src">
    </label>
  </ng-template>
    
  <label
    *ngIf="clearable"
    class="label__secondary"
    [ngClass]="{ 'start': placement == 'start', 'end': placement == 'end' }"
    (click)="value = ''; onClear.emit()">
    <img src="./assets/img/icons/x.svg">
  </label>

  <input
  [style]="'text-align: ' + textAlign"
  [disabled]="disabled ?? _disabled"
  [required]="required ?? _required ?? false"
  [readOnly]="readOnly"
  [ngClass]="{ 'auto-height': autoHeight, 'searchable': searchIcon && !customLabel, 'clearable': clearable, 'rounded': design == 'rounded', 'normal': design == 'normal' }"
  [placeholder]="placeholder"
  [type]="type"
  [max]="max"
  [min]="min"
  [(ngModel)]="value"
  (keyup)="onChange.emit(value)"
  (keyup.enter)="onEnter.emit(value)"
  (blur)="onBlur()">
</div>