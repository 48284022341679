import { Component, Input } from '@angular/core';

@Component({
  selector: 'evaluation-feedback',
  templateUrl: './evaluation-feedback.component.html',
  styleUrls: ['./evaluation-feedback.component.scss']
})
export class EvaluationFeedbackComponent {
  @Input() level: number = 0;
  @Input() isNotApplicable = false;
  @Input() feedback: any;
}
