import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import * as fromApp from "../../ngrx/app.reducers";
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { RedirectService } from '../../shared/services/redirect.service';
import { Store } from '@ngrx/store';
import { LangsService } from '../services/langs.service';
import { ApplicationModalMessage } from '../ngrx/core.reducers';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import * as CoreActions from "../../core/ngrx/core.actions";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isPeopleAppraisal: boolean = false;
  @Input() isOperations: boolean = false;
  @Input() isSelfAssessment: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() isSti: boolean = false;
  @Input() avatar?: string;
  @Input() isTeamPage?: boolean = false;
  @Input() showImpersonateBanner?: boolean = false;
  @Input() pageName?: string = '';
  @Input() tooltipModalMessage: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: '',
    applicationModalButtonCloseText: ''
  };
  @Input() disableAccessToPas: boolean = false;
  initials?: string;
  userName: string = '';
  notificationCount: number = 10;
  isMobile: boolean = false;

  showNotificationPanel: boolean = false;
  notificationValues: DropdownItem[] = [];
  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTooltipClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNotificationClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUserLogoClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPeopleAppraisalLogoClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAppLogoClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeProfile: EventEmitter<any> = new EventEmitter<any>();
  @Output() onToggleSidebar: EventEmitter<any> = new EventEmitter<any>();

  noDataObject: DropdownItem = {
    id: 'none',
    name: '',
    icon: '/assets/img/icons/coffee.svg'
  }
  loggedUser: any;
  languageToUse: any;
  isUserPageDetail: boolean = false;
  notificationsCounter: number = 0;
  notificationsPollingStarted: boolean = false;
  result$: Subscription = new Subscription();
  notificationInitials: any;

  @ViewChild("headerSearchNotification") notifications?: ElementRef;
  isAdminModule: boolean = false;
  profileChangeMenuOpen: boolean = false;
  profileMenuItems: any[] = [];
  isLoadingProfiles: boolean = true;
  canToggleManagerAndCollaborator: boolean = false;
  isSwitchingProfile: boolean = false

  constructor(public translate: TranslateService,
    public redirectService: RedirectService,
    public sidebarService: SidebarService,
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private router: Router,
    private modalService: ModalService,
    private analyticsService: AnalyticsService
  ) {
    const notificationsCounter$: Observable<number> = this.store.select(fromApp.getNotificationsCounter);
    const notificationsInitials$: Observable<any> = this.store.select(fromApp.getNotificationsInitials);
    // Sto in ascolto dei vari cambiamenti
    const combinedSelectes$ = combineLatest([notificationsCounter$, notificationsInitials$]);
    this.result$ = combinedSelectes$.subscribe(
      ([notificationsCounter, notificationsInitials]) => {
        this.notificationsCounter = notificationsCounter;
        this.notificationInitials = notificationsInitials;
        if (!this.notificationsPollingStarted) {
          this.store.dispatch(CoreActions.StartCountNotifications());
          this.store.dispatch(CoreActions.StartGetNotificationsInitials());
          this.notificationsPollingStarted = true;
        }
      })
  }

  ngOnInit() {
    this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
    this.store.select(fromApp.getLoggedUser)
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.avatar = this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage;
            this.languageToUse = this.langsService.getUserLang(this.loggedUser.user);

            this.isUserPageDetail = !(window.location.href.indexOf('/selfAssessment/home') > -1);
            const innerWidth = window.innerWidth;
            if (innerWidth < 600) {
              this.isMobile = true;
            }
            this.initials = this.loggedUser.surname[0] + this.loggedUser.forename[0];
            this.userName = this.loggedUser.forename + ' ' + this.loggedUser.surname;
            this.noDataObject.name = this.translate.instant('notifications.NO_DATA');
            this.canToggleManagerAndCollaborator = this.isSelfAssessment && this.isPeopleAppraisal && !this.isAdminModule;
            this.translate.get(
              [
                'menu.MANAGER',
                'menu.SELFASSESSMENT',
                'menu.ADMIN',

              ])
              .subscribe(translations => {
                this.profileMenuItems = [];
                if (this.isSelfAssessment) {
                  this.profileMenuItems.push(
                    {
                      id: 'selfAssessment',
                      description: translations['menu.SELFASSESSMENT'],
                      icon: '/assets/img/icons/user.svg',
                    })
                }
                if (this.isPeopleAppraisal) {
                  this.profileMenuItems.unshift(
                    {
                      id: 'peopleAppraisal',
                      description: translations['menu.MANAGER'],
                      icon: '/assets/img/icons/users.svg',
                    })
                }
                if (this.isAdmin) {
                  this.profileMenuItems.push(
                    {
                      id: 'admin',
                      description: translations['menu.ADMIN'],
                      icon: '/assets/img/icons/settings.svg',
                    })
                }
                this.isLoadingProfiles = false;
              })
          }
        });
  }
  ngAfterContentChecked(){
    this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
    this.canToggleManagerAndCollaborator = this.isSelfAssessment && this.isPeopleAppraisal && !this.isAdminModule;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.showNotificationPanel && this.notifications) {
      if (!this.notifications.nativeElement.contains(event.target)) {
        this.showNotificationPanel = false;
      }
    }
  }

  // Per il cambio vista dei self assessment
  selfAssessmentView() {
    return window.location.href.indexOf('/selfAssessment/') > -1;
  }
  peopleAppraisalView() {
    return window.location.href.indexOf('/peopleAppraisal/') > -1;
  }

  adminView() {
    return window.location.href.indexOf('/admin/') > -1;
  }

  // Gestione sidebar
  isSidebarOpened() {
    return this.sidebarService.isNavOpen();
  }

  // Toggle della sidebar
  toggleSidebar() {
    this.onToggleSidebar.emit();
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }

  // Gestione eventi
  emitOnUserLogoClicked() {
    if (!this.disableAccessToPas) {
      this.onUserLogoClicked.emit();
    }
  }

  isProfileOrTeamPage() {
    if (window.location.href.indexOf('profile') || window.location.href.indexOf('myTeam')) {
      return true
    } else {
      return false;
    }
  }

  // Gestione eventi
  emitOnPeopleAppraisalLogoClicked() {
    this.onPeopleAppraisalLogoClicked.emit();
  }

  // Azione se logo performance care cliccato
  emitOnAppLogoClicked() {
    this.redirectService.goToHome(true);
  }

  // Vai alla pagina delle notifiche
  goToNotificationPage() {
    this.redirectService.goToNotifications();
  }

  // Porta alla pagina di supporto
  goToSupport() {
    this.redirectService.goToSupport();
  }

  emitOnNotificationClicked($event?: any) {
    /* this.showNotificationPanel = !this.showNotificationPanel;
    this.onNotificationClicked.emit()
    $event.stopPropagation(); */
    this.redirectService.goToNotifications();
  }

  openChangeProfileMenu() {
    this.profileChangeMenuOpen = !this.profileChangeMenuOpen;
  }

  closeProfileMenu() {
    this.profileChangeMenuOpen = false;
  }

  toggleManagerAndCollaborator() {
    if (this.isSwitchingProfile) {
      return
    }
    this.isSwitchingProfile = true;

    if (this.peopleAppraisalView()) {
      this.emitOnSwitchProfile('selfAssessment');
    } else {
      this.emitOnSwitchProfile('peopleAppraisal');
    }
  }

  emitOnSwitchProfile(switchTo: string) {
    this.profileChangeMenuOpen = false;
    if (switchTo == 'admin') {
      this.isAdminModule = true;
      this.router.navigate(["/admin/home"]);
    } else if (switchTo == 'peopleAppraisal') {
      this.isAdminModule = false;
      this.router.navigate(["/peopleAppraisal/homepage"]);
    } else if (switchTo == 'selfAssessment') {
      this.isAdminModule = false;
      this.router.navigate(["/selfAssessment/home"]);
    }
    this.onChangeProfile.emit(switchTo)
    setTimeout(() => {
      this.isSwitchingProfile = false;
    }, 300)
  }

  getTimeFromNow(date: Date): string {
    return moment(date).fromNow();
  }
}