import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SenecaResponse } from 'atfcore-commonclasses';

@Component({
  selector: 'app-local-login',
  templateUrl: './local-login.component.html',
  styleUrls: ['./local-login.component.scss']
})
export class LocalLoginComponent implements OnDestroy {
  isFetchingLangs: boolean = false;
  langs: any;
  result$: any;
  login: boolean = true;
  emailControl: UntypedFormControl = new UntypedFormControl;
  initUserPasswordRecovery$: Subscription = new Subscription();

  usernameModalMessage: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  passwordModalMessage: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  loginForm = {
    email: '',
    password: ''
  }

  showLoader: boolean = false;
  iconRight: string = '';
  typeInput: string = 'password';
  iconRightEye: string = 'assets/img/icons/eye.svg'

  userId: any;
  singleUseTokenForConfirmRegistration: any;
  newValidationToken: any;
  isConfirmUserRegistration: any;
  userConfirmation$: Subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute) {
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    let availableLangs$: any = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest([availableLangs$, isFetchingLangs$]);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
    this.emailControl = new UntypedFormControl('', { validators: [Validators.required, Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$')] });
  }

  ngOnInit() {

    this.route.params
      .subscribe(
        (params: Params) => {
          this.userId = params['userId'];
          this.singleUseTokenForConfirmRegistration = params['singleUseTokenForConfirmRegistration'];
          this.newValidationToken = null;
          if (this.router.url.indexOf('confirmUserRegistration') !== -1) {
            this.isConfirmUserRegistration = true;
            this.userConfirmation$ = this.authService.confirmUserRegistration(this.userId, this.singleUseTokenForConfirmRegistration)
              .subscribe(data => {

                if (data?.error) {

                } else {
                  //apro la modale se il servizio non va in errore 
                  this.modalService.open('modalSuccessfulSignUp')
                }
                this.isConfirmUserRegistration = false;
              });
          } else {
            this.isConfirmUserRegistration = false;
          }
        });

    this.usernameModalMessage = {
      modalId: "143",
      title: this.translate.instant('login.modals.EMAIL'),
      subtitle: this.translate.instant('login.modals.EMAIL_SUBTITLE'),
      text: this.translate.instant('login.modals.EMAIL_DESCR')
    }
    this.passwordModalMessage = {
      modalId: "144",
      title: this.translate.instant('login.modals.PASSWORD'),
      subtitle: this.translate.instant('login.modals.PASSWORD_SUBTITLE'),
      text: this.translate.instant('login.modals.PASSWORD_DESCR')
    }
  }

  ngOnDestroy() {
    this.result$.unsubscribe();

    if (this.initUserPasswordRecovery$) {
      this.initUserPasswordRecovery$.unsubscribe();
    }
  }

  // Quando scrivi sull'email
  emailChanged(text: string) {
    this.loginForm.email = text;
  }

  // Quando scrivi sulla password
  passwordChanged(text: string) {
    this.loginForm.password = text;
  }

  // Schiaccio pulsante accedi
  onLogin() {
    this.showLoader = true;
    setTimeout(() => {
      this.showLoader = false;
    }, 1300)
    const email = this.loginForm.email;
    const password = this.loginForm.password;
    this.store.dispatch(AuthActions.DoLogin({ email: email, password: password }));
  }

  // Pulsante login è disabilitato
  loginButtonIsActive() {
    if (this.loginForm.email && this.loginForm.email.trim().length && this.loginForm.password && this.loginForm.password.trim().length) {
      return true;
    }
    return false;
  }

  // Valida l'email
  emailValidate() {
    let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
    if (regex.test(this.loginForm.email.trim())) {
      return true;
    }
    return false;
  }

  //Nel caso in cui l'email non fosse valida e il l'input touched mostra l'icona rossa
  emailError() {
    if (!this.emailValidate() && this.loginForm.email && this.loginForm.email.trim().length && this.emailControl.touched) {
      this.iconRight = "assets/img/icons/alert-triangle.svg"
      return true
    }
    this.iconRight = ''
    return false
  }
  //Rende possibile la visualizzazione della password cambiando il tipo di Input
  changeTypeInput(type: string) {
    if (type && type === 'password') {
      this.typeInput = 'text'
      this.iconRightEye = 'assets/img/icons/eye-off.svg'
    }
    else if (type && type === 'text') {
      this.typeInput = 'password'
      this.iconRightEye = 'assets/img/icons/eye.svg'
    }
  }

  //Cambia pagina da accedi in registrati
  goToSignUp(event: boolean) {
    this.router.navigate(["/signUp"]);
  }

  //Apre modale recupera password
  openPasswordRecoveryModal() {
    this.modalService.open('modalPasswordRecovery');
  }

  //Chiude modale recupera password e invia l'email di recupero solo se l'email esiste ed è scritta con il formato richiesto
  closePasswordRecoveryModal(close?: boolean) {
    this.modalService.close('modalPasswordRecovery');
    if (this.loginForm?.email?.trim().length && this.emailValidate()) {
      if (!close) {
        //Chiama la funzione per il recupera della password e apre la modale Mail inviata
        this.initUserPasswordRecovery()
        this.modalService.open('modalEmailSent');
      }
    } else if (!close) {
      this.modalService.open('modalEmailWarning');
    }
  }

  //Servizio che invia l'email di recupero password
  initUserPasswordRecovery() {
    this.initUserPasswordRecovery$ = this.authService.initUserPasswordRecovery(this.loginForm.email)
      .subscribe((data: SenecaResponse<any>) => {

      },
        (err: string) => {

        })
  }

  //Chiudo modale
  closeEmailWarningModal() {
    this.modalService.close('modalEmailWarning');
  }

  closeEmailSentModal() {
    this.modalService.close('modalEmailSent');
  }

  closeSuccessfulSignUpModal() {
    this.modalService.close('modalSuccessfulSignUp');
  }

}
