<div class="text-goal-container">
  <!-- TAG Obiettivo -->
  <div *ngIf="tagTitle" class="tag-container">
    <div class="divisor-line-goal"></div>
    <div class="text-goal">
      <p class="tag">{{ tagTitle }}</p>
      <p> {{title}}</p>
    </div>
  </div>

  <div class="divisor-line"></div>

  <!-- Valutazione -->
  <div class="evaluation-rating-container">
    <div class="text-container">
      <p class="title">{{('textGoal.GOAL_EVALUATION' | translate)}}<span
          *ngIf="evaluationVote == undefined || evaluationVote == null">&nbsp;*</span></p>
      <svg-icon src="../../../../assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
        (click)="openShowModal()"></svg-icon>
    </div>
    <competence-evaluation-system [direction]="'row'" [size]="'big'" [kind]="'default'" [items]="5"
      [readOnly]="readonly || disabled" [level]="evaluationVote" [disabledFirstStar]="true"
      (onButtonClicked)="starEvaluation($event)" [aspect]="readonly ? 'collaborator' : 'manager' " [showTitles]="true"
      [showDescr]="false" [titleStyleAsDescription]="true">
    </competence-evaluation-system>
  </div>

  <div class="divisor-line"></div>

  <div *ngIf="showMenu" id="{{ id + '-title' }}" class="goal-title-container">
    <!-- Menu edit -->
    <div class="menu-btn" (clickOutside)="isMenuOpen = false;">
      <p *ngIf="isToApprove" class="new-badge" translate="generic.NEW"></p>
      <svg-icon src="assets/img/icons/more-vertical.svg" [applyClass]="true" class="menu-icon" (click)="toggleMenu()">
      </svg-icon>
      <!-- Dropdown -->
      <ng-container *ngIf="isMenuOpen">
        <div class="dropdown-menu">
          <ng-container *ngFor="let option of menuOptions">
            <alloy-dropdown-item (click)="onMenuOptionClicked(option.id)" class="full-width" [item]="option">
            </alloy-dropdown-item>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- Text area valutazione -->
  <ng-container *ngIf="isEvaluation">
    <input-container id="{{ id + '-description' }}" [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
      [isValued]="evaluation && evaluation.trim().length" [isObbligatory]="false"
      title="{{customTitle ? customTitle : 'textGoal.INSERT_LABEL' | translate}}" [disabled]="false">
      <alloy-text-area [bindValue]="evaluation" [maxlength]="300" (onModelChanged)="onEvaluationChanged($event)"
        placeholder="{{ customPlaceholder? customPlaceholder: 'textGoal.INSERT_PLACEHOLDER' | translate}}"
        [readonly]="disabled || readonly">
      </alloy-text-area>
    </input-container>
  </ng-container>
  <!-- Count aggiornamenti -->
  <!-- <ng-container *ngIf="isNotificationUpdates">
    <p class="text-notification-container" *ngIf="countUpdates">
      <span class="count-notification">{{countUpdates}}</span>
      <span class="text-notification">{{'updateGoals.UPDATES_ADDED' | translate }}</span>
      <span class="text-notification underline" (click)="openShowModal()">{{'updateGoals.SHOW' | translate }}</span>
    </p>
    <p *ngIf="!countUpdates">
      <span class="text-notification">{{'updateGoals.NO_UPDATE_POSTED' | translate }}</span>
    </p>
  </ng-container> -->
  <!-- Text footer -->
  <ng-container *ngIf="textFooter">
    <p class="text-footer" [innerHTML]="textFooter"></p>
  </ng-container>
</div>