import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfCareAssessmentPhaseKeys, PerfCareMacroGoalTypes } from 'src/commonclasses';
import moment from 'moment';
import { FINAL_STATUSES } from 'src/app/utils/goal.utils';

@Component({
  selector: 'goal-card-detail',
  templateUrl: 'goal-card-detail.component.html',
  styleUrls: ['./goal-card-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoalCardDetailComponent implements OnInit {
  @Input() goal?: any;
  @Input() isSelfAssessment?: boolean;
  @Input() isPeopleAppraisal?: boolean;
  @Input() peopleAppraisalData?: any;
  @Input() commentData?: any;
  @Input() isInnerHtml?: boolean;
  @Input() isOperations?: boolean;
  @Input() loggedUser?: any;
  @Input() forceAllowEdit?: boolean;
  @Input() forceAllowDelete?: boolean;
  @Input() hasDrafts?: boolean;
  @Input() isAfterSharing?: boolean;
  @Input() runningPhase?: any;
  @Input() isHistory?: boolean;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onApprove: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onReadComment: EventEmitter<any> = new EventEmitter();
  @Output() onEditComment: EventEmitter<any> = new EventEmitter();
  @Output() onUploadFile: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteLink: EventEmitter<any> = new EventEmitter();
  @Output() onEditLink: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteFile: EventEmitter<any> = new EventEmitter();
  @Output() onEditFile: EventEmitter<any> = new EventEmitter();
  @Output() onLinkOpen: EventEmitter<any> = new EventEmitter();
  @Output() onFileDownload: EventEmitter<any> = new EventEmitter();

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit() {
    /* if(this.goal.deadline) {
      this.goal.deadline = moment(this.goal.deadline).utc().format('DD/MM/YYYY');
    } */
  }

  // Recupera la stringa per l'estensione del file
  getExtensionString(fileName: string) {
    let extension = fileName.slice(fileName.lastIndexOf('.'), fileName.length);
    extension = extension.replace('.', '');
    if (extension == 'pptx' || extension == 'PPTX') {
      extension = 'ppt';
    } else if (extension == 'jpg' || extension == 'JPG') {
      extension = 'jpeg';
    } else if (extension == 'xlsx' || extension == 'XLSX') {
      extension = 'xls';
    } else if (extension == 'docx' || extension == 'DOCX') {
      extension = 'doc';
    } else if (extension == 'eml' || extension == 'EML') {
      extension = 'eml';
    } else if (extension == 'msg' || extension == 'MSG') {
      extension = 'msg';
    } else if (extension == 'txt' || extension == 'TXT') {
      extension = 'txt';
    } else if (extension == 'rtf' || extension == 'RTF') {
      extension = 'rtf';
    } else if (extension == 'png' || extension == 'PNG') {
      extension = 'png';
    }
    return extension;
  }

  // Se il pulsante modifica è disabilitato
  canEdit() {
    let notEditablePeopleAppraisal = [PerfCareMacroGoalTypes.STRUCTURE, PerfCareMacroGoalTypes.OPERATIONS_KPI, PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES, PerfCareMacroGoalTypes.DEVELOPMENT, PerfCareMacroGoalTypes.COMPANY, PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS];
    let notEditableContributor = [PerfCareMacroGoalTypes.STRUCTURE, PerfCareMacroGoalTypes.OPERATIONS, PerfCareMacroGoalTypes.OPERATIONS_KPI, PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES, PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT, PerfCareMacroGoalTypes.INDIVIDUAL, PerfCareMacroGoalTypes.COMPANY];
    if (this.isHistory) {
      return false;
    } else if (this.goal.status == 'completed') {
      return false;
    } else if (this.isAfterSharing) {
      return true;
    } else {
      if (this.hasDrafts) {
        if (this.goal.originalGoalId) {
          return true
        }
        return false;
      } else {
        if (this.isPeopleAppraisal) {
          if (!notEditablePeopleAppraisal.includes(this.goal.type) && this.runningPhase.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
            let today = moment().format();
            let phaseKickoff = moment(this.runningPhase.phaseStartDateForManager).format();
            let isExpired = FINAL_STATUSES.includes(this.goal.goalStatus);
            return moment(today).isSameOrBefore(phaseKickoff) && !isExpired;
          }
          if (notEditablePeopleAppraisal.includes(this.goal.type)) {
            return false;
          }
          return true;
        } else if (this.isSelfAssessment) {
          if (!notEditableContributor.includes(this.goal.type) && this.runningPhase.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
            let today = moment().format();
            let phaseKickoff = moment(this.runningPhase.phaseStartDateForManager).format();
            let isExpired = FINAL_STATUSES.includes(this.goal.goalStatus);
            return moment(today).isSameOrBefore(phaseKickoff) && !isExpired;
          }
          if (notEditableContributor.includes(this.goal.type)) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      }
    }
  }

  canDelete() {
    if (this.isHistory) {
      return false;
    } else if (this.runningPhase.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
      return false;
    } else {
      if (this.isAfterSharing) {
        if (this.goal.goalStatus.indexOf('DRAFT') > -1 || !this.goal.originalGoalId) {
          return true;
        }
        return false;
      } else {
        if (this.isPeopleAppraisal) {
          if (this.goal.type == PerfCareMacroGoalTypes.STRUCTURE || this.goal.type == PerfCareMacroGoalTypes.OPERATIONS_KPI || this.goal.type == PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES || this.goal.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT || this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT || this.goal.type == PerfCareMacroGoalTypes.COMPANY) {
            return false;
          } else if (this.goal.type == PerfCareMacroGoalTypes.INDIVIDUAL && this.goal.status != 'inProgress' && this.goal.status != 'completed') {
            return true;
          }
          return false;
        } else if (this.isSelfAssessment) {
          if (this.goal.type == PerfCareMacroGoalTypes.STRUCTURE || this.goal.type == PerfCareMacroGoalTypes.OPERATIONS_KPI || this.goal.type == PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES || this.goal.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT || this.goal.type == PerfCareMacroGoalTypes.INDIVIDUAL || this.goal.type == PerfCareMacroGoalTypes.COMPANY) {
            return false;
          } else if (this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT && this.goal.status != 'inProgress' && this.goal.status != 'completed') {
            return false;
          }
          return;
        } else {
          return false;
        }
      }
    }
  }

  // Se può fare le azioni nei file e nei links
  canDoActions(object: any) {
    if ((this.loggedUser && this.loggedUser.userId) == object.creationUserId) {
      return true;
    } else {
      return false;
    }
  }

  emitOnEditClicked() {
    this.onEdit.emit();
  }

  emitOnApproveClicked() {
    this.onApprove.emit();
  }

  emitOnDeleteClicked() {
    this.onDelete.emit();
  }

  emitOnReadComment() {
    this.onReadComment.emit();
  }

  emitOnEditComment() {
    this.onEditComment.emit();
  }

  emitOnUploadFile() {
    this.onUploadFile.emit();
  }

  emitOnDeleteLink(id: string) {
    this.onDeleteLink.emit(id);
  }
  emitOnEditLink(id: string) {
    this.onEditLink.emit(id);
  }

  emitOnDeleteFile(id: string) {
    this.onDeleteFile.emit(id);
  }

  emitOnEditFile(id: string) {
    this.onEditFile.emit(id);
  }

  emitOnLinkOpen(url: string) {
    this.onLinkOpen.emit(url);
  }

  emitOnFileDownload(file: any) {
    this.onFileDownload.emit(file);
  }
}