<!-- Barra di ricerca -->
<div class="search-filters-container">
  <ng-container *ngIf="searchEnabled">
    <div class="admin-search-container">
      <div class="search-wrapper">
        <alloy-input (onIconClicked)="applyFilters()" typeInput="text" [bindValue]="searchedText"
          (onModelChanged)="emitSearchedTextChanged($event)" iconRight="assets/img/icons/search.svg"
          [placeholder]="searchByTextPlaceholder || ('generic.SEARCH_BY' | translate)" [isSearchBar]="true">
        </alloy-input>
      </div>
    </div>
  </ng-container>
  <!-- Recap filtri -->
  <div class="filters-recap-container">
    <div class="left-column">
      <ng-container *ngIf="!selectedFiltersNumber">
        <p class="placeholder">{{ noActiveFilterText || ('adminSearch.NO_FILTER_ACTIVE' | translate) }}</p>
      </ng-container>
      <ng-container *ngIf="!selectedFiltersNumber && searchedText && searchedText.length">
        <p class="placeholder" [innerHTML]="oneFilterText || ('adminSearch.ONE_FILTER' | translate)"></p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber === 1">
        <p class="placeholder" [innerHTML]="oneFilterText || ('adminSearch.ONE_FILTER' | translate)"></p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber > 1">
        <p class="placeholder"><b>{{selectedFiltersNumber}}</b>{{ moreFiltersText || (' ' + ('adminSearch.MORE_FILTERS'
          |
          translate)) }}</p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber > 1">
        <p class="delete-filters" (click)="clearFilters()">{{ deleteFiltersText || ('adminSearch.DELETE_FILTERS' |
          translate) }}</p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber == 1">
        <p class="delete-filters" (click)="clearFilters()">{{ deleteFiltersText || ('adminSearch.DELETE_FILTER' |
          translate) }}</p>
      </ng-container>
      <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
    </div>
    <div class="right-column" (click)="openFiltersSection()">
      <p class="manage-filters">{{ manageFiltersText || ('adminSearch.MANAGE_FILTERS' |
        translate) }}</p>
      <ng-container *ngIf="!isFilterOpened">
        <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
      </ng-container>
      <ng-container *ngIf="isFilterOpened">
        <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
      </ng-container>
    </div>
  </div>
  <!-- contenitore filtri -->
  <ng-container *ngIf="isFilterOpened && filtersObject">
    <div class="filters-container">
      <div class="filters-content" *ngIf="!isLoadingFiltersData">
        <div class="generic-container">
          <!-- Componente select dinamica-->
          <ng-container *ngFor="let filter of filterList">
            <input-container [isValued]="filter.selected" [title]="filter.title" [isWithoutInfoIcon]="true">
              <alloy-select [disabled]="isLoadingFiltersData" [inMinWidthForIe]="true" [selectedItem]="filter.selected"
                [items]="filter.list" [searchable]="true" bindLabel="id"
                (onSelectedItem)="selectFilter($event, filter?.id)" [placeholder]="'generic.SERACH_DOTS' | translate">
              </alloy-select>
            </input-container>
          </ng-container>
        </div>

        <div class="button-actions filters">
          <alloy-button [label]="applyFiltersText || ('generic.APPLY_FILTERS' | translate)" type="primary fill"
            (onButtonClicked)="applyFilters()" [disabled]="isLoadingUsers || isLoadingFiltersData">
          </alloy-button>
        </div>
      </div>

      <!-- loader -->
      <ng-container *ngIf="isLoadingFiltersData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>