import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'competence-evaluation-system',
  templateUrl: './competence-evaluation-system.component.html',
  styleUrls: ['./competence-evaluation-system.component.scss']
})
export class CompetenceEvaluationSystemComponent implements OnInit {
  /**
   * @ignore  
   * Array di dati(evaluation-item) presenti 
   */
  public data!: any[];

  /**
   * Imposta se gli evalution-item e messaggio devono essere visti in orizzontale o verticale
  */
  @Input() direction!: "row" | "column";

  /**
   * Imposta gli evaluation-item da settare con active a true
   */
  @Input() level!: Level | null | undefined;

  /**
   * Imposta la dimensione degli evaluation-item
   */
  @Input() size!: "big" | "small";

  /**
   * Imposta l'aspetto
   */
  @Input() aspect!: "manager" | "collaborator";

  /**
  * Imposta l'icona da mostrare per la valutazione
  */
  @Input() kind: string = "default";

  /**
  * Numero di item presenti 
  */
  @Input() items!: number;

  /**
   *  Testo se selezionato item 0
   */
  @Input() valueZeroStar?: string;

  /**
   *  Testo se selezionato item 0
   */
  @Input() valueZeroStarSub?: string;

  /**
   *  Testo se selezionato solo primo item
   */
  @Input() valueFirstStar?: string;

  /**
   *  Testo se selezionato solo primi 2 item
   */
  @Input() valueSecondStar?: string;

  /**
  *  Testo se selezionato solo primi 3 item
  */
  @Input() valueThirdStar?: string;

  /**
   *  Testo se selezionato solo primi 4 item
   */
  @Input() valueFourthStar?: string;

  /**
  *  Testo se selezionato tutti gli item
  */
  @Input() valueFifthStar?: string;

  /**
   *  Testo se selezionato solo primo item
   */
  @Input() valueFirstStarSub!: string;

  /**
   *  Testo se selezionato solo primi 2 item
   */
  @Input() valueSecondStarSub!: string;

  /**
  *  Testo se selezionato solo primi 3 item
  */
  @Input() valueThirdStarSub!: string;

  /**
   *  Testo se selezionato solo primi 4 item
   */
  @Input() valueFourthStarSub!: string;

  /**
  *  Testo se selezionato tutti gli item
  */
  @Input() valueFifthStarSub!: string;

  /**
  *  Deseleziona la prima stella 
  */
  @Input() disabledFirstStar = false;

  /**
  *  Rende tutto il componente readOnly
  */
  @Input() readOnly = false;

  /**
   * Visualizzazione label non disponibile (N/A)
   */
  @Input() isNotApplicable?: boolean = false;
  /**
     * Possibilità di valutare (N/A)
     */
  @Input() canValueNA?: boolean = false;
  /**
  *  Testo se selezionato N/A come "valutazione"
  */
  @Input() valueNA!: string;
  /**
  *  Testo se selezionato N/A come "valutazione"
  */
  @Input() valueNASub!: string;
  /**
  *  Mostra nome della valutazione
  */
  @Input() showTitles = false;
  /**
    *  Mostra descrizione della valutazione
    */
  @Input() showDescr = true;
  /**
   * Definisce quali label usare per le scale di valutazione
   */
  @Input() competenceScale: boolean = false;
  /**
   * Usa lo stile della descrizione per mostrare i titoli della scala,
   * utile es nella valutazione obiettivi (1 step)
   */
  @Input() titleStyleAsDescription: boolean = false;
  // cambia il colore della valutazione, per confronto nello step competenze
  @Input() secondaryColor: boolean = false;


  /**
  *  Invia il numero di stelle selezionate 
  */
  @Output() onButtonClicked: EventEmitter<Level> = new EventEmitter();


  keys: number[] = [];
  newObject: any;

  constructor() { }

  ngOnInit(): void {
    this.newObject = {};
    if (!this.canValueNA) {
      for (let i = 1; i < this.items + 1; i++) {
        this.keys.push(i);
        this.newObject[i] = {
          active: this.level !== null && this.level !== undefined && this.level >= 0 && this.level >= i ? true : false,
          disabled: this.disabledFirstStar && i == 0 ? true : false,
          kind: this.kind == 'default' ? 'star' : ''
        }
      }
    } else {
      for (let i = -1; i <= this.items; i++) {
        if (i != 0) {
          this.keys.push(i);
          this.newObject[i] = {
            active: this.level !== null && this.level !== undefined && this.level >= 0 && this.level >= i ? true : false,
            disabled: this.disabledFirstStar && i == 0 ? true : false,
            kind: i == -1 ? 'na' : this.kind == 'default' ? 'star' : ''
          }
        }
      }
    }
    this.setLevel();
  }

  /**
   *
   * @ignore
   * @param changes Oggetto con i valori cambiati
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.direction == undefined)
      this.direction = "column";

    this.setLevel();
  }

  setLevel() {
    // se la valutazione è NA, attivo solo quello e disattivo tutto il resto
    if (this.level && this.newObject) {
      if (this.level == -1) {
        this.newObject[-1].active = true;
        for (let i = 1; i <= this.items; i++) {
          this.newObject[i].active = false;
        }
      } else {
        // altrimenti se è un qualsiasi altro, se c'è NA, lo disattivo, poi attivo tutti fino al livello selezionato 
        if (this.canValueNA) {
          this.newObject[-1].active = false;
        }
        // resetto gli active
        for (let i = 1; i <= this.items; i++) {
          this.newObject[i].active = false;
        }
        // poi riattivo tutti i necessari, la condizione qui è <= perchè level adesso è esattamente l'indice
        for (let i = 1; i <= this.level; i++) {
          this.newObject[i].active = true;
        }
      }
    }
  }

  /**
   * Viene inviato un evento al padre, se il pulsante è disabilitato l'evento non viene lanciato.
   * @param event 
  */
  emitOnClick(level: number) {
    if (!this.readOnly) {
      this.level = level;
      this.setLevel();
      this.onButtonClicked.emit(this.level);
    }
  }
}


export type Level = -1 | Exclude<Partial<number>, 0>;