<div class="box-preview d-flex flex-column justify-content-center align-items-center">
  <div class="preview-container">
    <div *ngIf="doc.file && !doc.file.fileExtension || imageErrors" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" class="drag-and-drop d-flex flex-column justify-content-center align-items-center">
      <div class="file-upload-container">
        <p class="text-big" translate="uploadFile.CHARGED_WRONG_FILE"></p>
        <a class="d-flex flex-row justify-content-start align-items-center" (click)="uploadDoc()">
          <h4 class="upload-link">
            {{'uploadFile.UPDATE_FILE' | translate}}
          </h4>
        </a>
      </div>
    </div>
    <div *ngIf="(doc.file && doc.file.fileExtension) && !isToReset" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" class="drag-and-drop d-flex flex-column justify-content-center align-items-center">
      <svg-icon class="big-size" [applyClass]="true" src="assets/img/icons/file/{{doc.file.fileExtension}}.svg">
      </svg-icon>
      <div class="file-upload-container">
        <p class="text-big">{{doc.name}}</p>
        <p class="text-small">{{doc.file.fileExtension}} file - {{doc.file.sizeRounded}}kB</p>
        <a class="d-flex flex-row justify-content-start align-items-center" (click)="uploadDoc()">
          <h4 class="upload-link">
            {{'uploadFile.UPDATE_FILE' | translate}}
          </h4>
        </a>
      </div>
    </div>

    <div *ngIf="!blob || isToReset" class="drag-and-drop d-flex flex-column justify-content-center align-items-center"
      (drop)="handleFileInputOnDrug($event)" (dragover)="allowDrag($event)">
      <svg-icon class="big-size" [applyClass]="true" src="assets/img/icons/upload-file-cloud.svg"></svg-icon>
      <div class="file-upload-container">
        <p class="title-section">{{'uploadFile.DRAG_FILE' | translate}}</p>
        <p class="text-small">{{'uploadFile.OR' | translate}}</p>
        <a class="d-flex flex-row justify-content-start align-items-center" (click)="uploadDoc()">
          <h4 class="upload-link">
            {{'uploadFile.CHOOSE_FILES' | translate}}
          </h4>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="hidden-input" (click)="uploadDoc()">
  <input type="file" id="uploadFile" (change)="handleFileInput($event.target)" [accept]="acceptedMimeTypes">
</div>

<ng-container *ngIf="!hideDefaultOption">
  <p class="upload-footer-text" translate="uploadFile.AVAILABLE_FORMATS"></p>
  <div class="modal-action-container">
    <alloy-button [label]="buttonLabel" type="primary fill" size="medium" [disabled]="!blob || isToReset"
      (onButtonClicked)="emitButtonClicked()">
    </alloy-button>
  </div>
</ng-container>