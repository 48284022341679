<ngx-guided-tour skipText="{{ 'tutorials.SKIP' | translate }}" nextText="{{ 'tutorials.NEXT' | translate }}"
  closeText="{{ 'tutorials.CLOSE' | translate }}" backText="{{ 'tutorials.BACK' | translate }}"
  doneText="{{ 'tutorials.DONE' | translate }}">
</ngx-guided-tour>

<!-- Sidebar con il menù principale -->
<sidebar [showAppHeader]="true" [hideCloseButton]="true" [sidenavTemplateRef]="navContent" direction="right"
  [navWidth]="325" [duration]="0.3" [hasImpersonateBanner]="showImpersonateBanner">
</sidebar>

<!-- Template con la sidebar principale (di destra) -->
<!-- isCppAdmin -->
<!--  [forceDisable]="forceDisableMenuItem(item)" -->
<ng-template #navContent>
  <div *ngIf="isAdmin || isCppAdmin" class="title-menu-container top">
    <p class="title-menu">{{ 'menu.SELECT_ACCOUNT' | translate }}</p>
  </div>
  <div *ngIf="loggedUser && (isAdmin || isCppAdmin) && selectedRole" class="switch-container">
    <alloy-radio (onItemChange)="onRadioSelect($event)" [switch]="true"
      [items]="rolesMenuItems" [bindValue]="selectedRole"></alloy-radio>
  </div>
  <div class="title-menu-container bottom" *ngIf="basicMenuItems.length">
    <p class="title-menu">{{ 'menu.NAVIGATION_MENU' | translate }}</p>
  </div>
  <alloy-dropdown-item [ngClass]="{'display-on-mobile': item.id =='tutorial'}" (click)="onHeaderOptionClicked(item)"
    class="full-width" *ngFor="let item of basicMenuItems; trackBy menuId" [isMenuItem]="true" [item]="item"
    [forceDisable]="forceDisableMenuItem(item)">
  </alloy-dropdown-item>
</ng-template>

<ng-container *ngIf="showImpersonateBanner && loggedUser">
  <div class="impersonate-header">
    <p
      [innerHTML]="('header.IMPERSONATING' | translate) + '<b>'+ loggedUser.forename + ' '+ loggedUser.surname +'</b>'">
    </p>
  </div>
</ng-container>

<!-- Header -->
<app-header *ngIf="showHeader()" [isPeopleAppraisal]="isPeopleAppraisal" [isSelfAssessment]="isSelfAssessment"
  [isAdmin]="isAdmin || isCppAdmin" [tooltipModalMessage]="headerTooltip" (onUserLogoClicked)="onGoToMySection()"
  (onToggleSidebar)="onToggleSidebar()" (onPeopleAppraisalLogoClicked)="goToMyTeam()" [isTeamPage]="isTeamPage"
  [showImpersonateBanner]="showImpersonateBanner" [disableAccessToPas]="disableAccessToPas"
  (onChangeProfile)="rolesAvailable()">
</app-header>

<!-- App -->
<router-outlet *ngIf="!isFetchingLangs && !showApplicationLoader" [hidden]="showApplicationLoader"></router-outlet>

<!-- Loader dell'applicazione, del caricamento delle pagine e del recupero delle lingue disponibili, o ogniqualvolta il valore "showApplicationLoader " nello Store è attivo -->
<div *ngIf="isFetchingLangs || showApplicationLoader" class="page-loading-container">
  <div>
    <img alt="sol" src="assets/img/icons/sol-logo.svg" />
  </div>
  <div>
    <p>Attendi</p>
  </div>
  <div>
    <h4>Ci siamo quasi</h4>
  </div>
  <div class="big-loader-spinner"></div>
</div>

<!-- Modale generica con un messaggio (ad esempio di errore piuttosto che di successo) -->
<modal id="modalMessage" class="message-modal-index" [isMessageModal]="true" (onClose)="closeModalMessage()"
  (onCancel)="closeModalMessage()" (onConfirm)="closeModalMessage()" [cancelLabel]="applicationModalButtonCloseText"
  [noConfirmButton]="noConfirmButton" [confirmLabel]="confirmLabel ? confirmLabel : 'CHIUDI'">
  <modal-text-content [kalturaCustomId]="kalturaCustomId" [modalWithKalturaScript]="modalWithKalturaScript"
    [withScrolledBody]="withScrolledBody" [modalId]="applicationModalId" [title]="applicationModalMessageTitle"
    [text]="applicationModalMessageText" [subtitle]="applicationModalMessageSubTitle"
    [image]="applicationModalMessageImage" [noBackground]="applicationModalMessageNoBackground">
  </modal-text-content>
</modal>

<!-- Modali per i badge -->
<ng-container *ngIf="applicationModalBadge && applicationModalBadge.length">
  <ng-container *ngFor="let badge of applicationModalBadge">
    <modal id="{{'modalBadge' + badge.badgeId}}" (onClose)="closeModalBadge(badge.badgeId)"
      (onCancel)="closeModalBadge(badge.badgeId)" (onConfirm)="closeModalBadge(badge.badgeId)"
      [confirmLabel]="closeBadgeModalLabel">
      <modal-text-content [badge]="badge"></modal-text-content>
    </modal>
  </ng-container>
</ng-container>


<!-- Modali log-out -->
<modal id="logout" (onClose)="closeLogoutModal()" (onCancel)="closeLogoutModal()" (onConfirm)="closeLogoutModal(true)"
  [confirmLabel]="'generic.CONFIRM' | translate" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content [title]="('generic.LOGOUT_TITLE' | translate)" [text]="('generic.LOGOUT_TEXT' | translate)">
  </modal-text-content>
</modal>