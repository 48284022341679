<div class="evaluation-rating-container">

  <ng-container *ngIf="!verticalStructure; else verticalStructureTem">
    <div class="avatar-evaluation-container" [ngClass]="{'more-columns': _rating && ratingValue}">
      <avatar-img [user]="user" [isHr]="isHr" [size]=" smallAvatar ? 'small' : 'medium'"></avatar-img>
      <div class="evaluation-container">
        <p class="evaluation-title">{{ evaluationTitle }}</p>
        <p class="evaluation-descr">{{ evaluationText || ('generic.NO_COMMENT_INSERTED' | translate) }}</p>
      </div>
    </div>

    <div class="divisor-vertical"></div>

    <div class="rating-container">
      <!-- <star-rating [forceRating]="rating" [grayColor]="isSelf" customContainerClass="no-padding"></star-rating> -->
      <competence-evaluation-system [direction]="'column'" [size]="'small'" [kind]="'default'" [items]="5" [readOnly]="true"
        [disabledFirstStar]="true" [level]="_level" [showTitles]="true" [showDescr]="false"
        [titleStyleAsDescription]="true"></competence-evaluation-system>

      <ng-container *ngIf="_rating">
        <div class="rating-value">{{ _rating | number: '1.2-2' }}</div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #verticalStructureTem>
    <div class="avatar-evaluation-container" [ngClass]="{'more-columns': _rating && ratingValue}">
      <avatar-img [user]="user" [isHr]="isHr" [size]=" smallAvatar ? 'small' : 'medium'"></avatar-img>
      <div class="evaluation-container">
        <p class="evaluation-title">{{ title }}</p>
        <div class="evaluation-wrap">
          <competence-evaluation-system [direction]="'row'" [size]="'small'" [kind]="'default'" [items]="5" [readOnly]="true"
            [level]="4" [disabledFirstStar]="true" [level]="_level" [showTitles]="true" [showDescr]="false"
            [titleStyleAsDescription]="true"></competence-evaluation-system>
          <ng-container *ngIf="_rating >= 0">
            <div class="rating-value">{{ _rating | number: '1.2-2' }}</div>
          </ng-container>
        </div>
        <!-- Punti di forza  -->
        <ng-container *ngIf="evaluationText">
          <p class="evaluation-descr-title">{{evaluationTitle}}</p>
          <p class="evaluation-descr">{{ evaluationText || ('generic.NO_COMMENT_INSERTED' | translate) }}</p>
        </ng-container>

        <!-- Area di miglioramento  -->
        <ng-container *ngIf="evaluationSecondText">
          <p class="evaluation-descr-title">{{evaluationSecondTitle}}</p>
          <p class="evaluation-descr">{{ evaluationSecondText || ('generic.NO_COMMENT_INSERTED' | translate) }}</p>
        </ng-container>

      </div>
    </div>

  </ng-template>
</div>