import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Performer, PerformerEvaluatorUtils } from 'src/app/utils/performer-evaluator';

@Component({
  selector: 'performer-evaluator-info-modal-content',
  templateUrl: 'performer-evaluator-info-modal-content.component.html',
  styleUrls: ['./performer-evaluator-info-modal-content.component.scss']
})
export class PerformerEvaluatorInfoModalContentComponent implements OnInit {
  // Informazioni costanti di tutte le tipologie di Performer
  performers: any;

  performersArray: Performer[] = [];

  // Eventuale chiave del performer da mostrare direttamente
  _selectedInfoTabPerformerKey?: string;
  @Input() set selectedInfoTabPerformerKey(value: string | undefined) {
    this._selectedInfoTabPerformerKey = value || '';
  }
  @Input() hideTitle: boolean = false;
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    const getPerformersPromise = PerformerEvaluatorUtils.getPerformers(this.translate);
    getPerformersPromise.then((res: any) => {
      this.performers = res;
      // Trasformo la mappa con tutti i livelli di valutazione in un array (mi serve per la modale contenente le informazioni)
      for (const key in this.performers) {
        this.performersArray.push(this.performers[key]);
      }
    })
  }

  // Cambia la selezione del livello selezionato di cui visualizzare le informazioni nella modale
  changeInfoTab(newKey: string) {
    this.selectedInfoTabPerformerKey = newKey;
  }
}