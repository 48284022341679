<div class="sidenav-informations-container">
  <div class="sidenav">
    <div class="total-info-container" *ngIf="isBackButton; else noBack">
      <div class="back-button-container">
        <alloy-button (onButtonClicked)="goBack()" label="{{'generic.GO_BACK'| translate}}"
          type="transparent arrow-icon" size="medium" iconLeft="/assets/img/icons/arrow-left.svg">
        </alloy-button>
      </div>
      <div class="data-info">
        <ng-container *ngIf="!isLoadingData">
          <p class="title" [innerHTML]="title" [ngClass]="{'light': textLight}"></p>
          <div class="white-divisor"></div>
          <p [innerHTML]="description"></p>
          <div class="full-width hide-xs-sm">
            <ng-content></ng-content>
            <!--kaltura-player *ngIf="true" [playerVisible]="true" [kalturaScript]="kalturaScript"
              customId="kalturaScript">
            </kaltura-player-->
          </div>
        </ng-container>
        <ng-container *ngIf="isLoadingData">
          <div class="loader-container">
            <div class="loader spinner-medium"></div>
          </div>
        </ng-container>
        <a *ngIf="false" class="white-link show-xs-sm" translate="generic.SEE_TUTORIAL"></a>
      </div>
    </div>

    <ng-template #noBack>
      <div class="container-title">
        <h1>Performance<br>
          <b>Management</b>
        </h1>
        <div class="white-divisor"></div>
        <p translate="generic.WELCOME_TO_THE_PLATFORM"></p>
      </div>
    </ng-template>

    <div class="logo-info-container hide-xs-sm">
      <div class="logo">
        <svg-icon class="alloy-logo" [applyClass]="true" src="/assets/img/icons/logo-sidebar.svg">
        </svg-icon>
      </div>
      <!--info rimosse perchè al momento non necessarie  
      <div class="year-info">
        <div class="info">
          <p>©{{currentYear}} Webranking</p>
        </div>
      </div>-->
    </div>
  </div>
</div>