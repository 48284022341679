import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SenecaResponse, GetNotificationListResponse } from 'atfcore-commonclasses';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PerfCareNotification } from 'src/commonclasses';


@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  getUserPasswordRecovery$: Subscription = new Subscription();
  uptdateEntiledPassword$: Subscription = new Subscription();
  isFetchingLangs: boolean = false;
  langs: any;
  result$: any;

  typeInput: string = 'password';
  iconRightEye: string = 'assets/img/icons/eye.svg'

  userId: any;
  validationToken: any;
  newPassword: string = '';

  passwordControl: UntypedFormControl = new UntypedFormControl;

  passwordRecoveryForm = {
    password: '',
    repeatPassword: ''
  }
  showLoader: boolean = false;
  firstInstructions: string = '';

  constructor(private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) {
    let availableLangs$: any = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest([availableLangs$, isFetchingLangs$]);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
  }

  ngOnInit(): void {
    this.validationToken = this.route.snapshot.paramMap.get('validationToken')
    this.userId = this.route.snapshot.paramMap.get('userId')
    this.passwordControl = new UntypedFormControl('', { validators: [Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&\'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$$')] });
  }

  ngOnDestroy() {
    this.result$.unsubscribe();

  }
  // Quando scrivi sulla password
  passwordChanged(text: string) {
    this.passwordRecoveryForm.password = text;
  }

  repeatPasswordChanged(text: string) {
    this.passwordRecoveryForm.repeatPassword = text;
  }

  // Schiaccio pulsante accedi
  confirmButton() {
    this.uptdateEntiledPassword()
    this.modalService.open('modalPasswordSet');
  }

  // Pulsante signUp è disabilitato
  confirmButtonIsActive() {
    if (this.passwordRecoveryForm.password && this.passwordRecoveryForm.password.trim().length && this.passwordRecoveryForm.repeatPassword && this.passwordRecoveryForm.repeatPassword.trim().length) {
      if (this.passwordRecoveryForm.password == this.passwordRecoveryForm.repeatPassword)
        return true;
    }
    return false;
  }

  // Valida password
  passwordValidate() {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{8,}$/;
    if (regex.test(this.passwordRecoveryForm.password.trim())) {
      return true;
    }
    return false;
  }

  // Valida password
  repeatPasswordValidate() {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{8,}$/;
    if (regex.test(this.passwordRecoveryForm.repeatPassword.trim())) {
      return true;
    }
    return false;
  }

  //Nel caso in cui la password non è valida e il l'input è touched mostra l'icona rossa
  passwordError() {
    if (!this.passwordValidate() && this.passwordRecoveryForm.password && this.passwordRecoveryForm.password.trim().length && this.passwordControl.touched) {
      return true
    }
    return false

  }

  //Controlla che la seconda password non contenga errori 
  repeatPasswordError() {
    if (this.passwordRecoveryForm.password !== this.passwordRecoveryForm.repeatPassword && this.passwordControl.touched && this.passwordRecoveryForm.repeatPassword.trim().length) {
      return true
    }
    else if (this.passwordError() && this.passwordRecoveryForm.repeatPassword && this.passwordRecoveryForm.repeatPassword.trim().length && this.passwordControl.touched) {
      return true
    }
    return false
  }

  //Controlla se entrambe le passoword sono corrette e coincidono
  isValid() {
    if (this.passwordRecoveryForm.password == this.passwordRecoveryForm.repeatPassword && this.passwordControl.touched && !this.repeatPasswordError() && !this.passwordError() && this.passwordRecoveryForm.password.trim().length && this.passwordRecoveryForm.repeatPassword.trim().length) {
      this.newPassword = this.passwordRecoveryForm.password
      return true
    }
    return false
  }

  //Rende possibile la visualizzazione della password cambiando il tipo di Input
  changeTypeInput(type: string) {
    if (type && type === 'password') {
      this.typeInput = 'text'
      this.iconRightEye = 'assets/img/icons/eye-off.svg'
    }
    else if (type && type === 'text') {
      this.typeInput = 'password'
      this.iconRightEye = 'assets/img/icons/eye.svg'
    }
  }

  //Cambia pagina da accedi in registrati
  goToSignIn() {
    this.router.navigate(["/localLogin"]);
  }

  //Servizio che recupera la password
  uptdateEntiledPassword() {
    this.uptdateEntiledPassword$ = this.authService.uptdateEntiledPassword(this.validationToken, this.userId, this.newPassword)
      .subscribe((data: SenecaResponse<any>) => {

      },
        (err: string) => {

        })
  }

}

